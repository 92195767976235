import * as React from 'react';
import { useState  } from 'react';
import {
    FormWithRedirect,
    TextInput ,
    useCreate,
    useDelete,
    useTranslate,
    SimpleForm,  
    Loading, 
    Error, 
    required, 
    Create,
    useRefresh,
    Query, 
    NumberInput ,
    SelectInput,
    SaveButton,
    BooleanInput,
    useDataProvider,
    ReferenceInput,
    AutocompleteInput,
    Toolbar,
    Button as Button2,
    useNotify
} from 'react-admin';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import CustomDatagrid from '../Components/CustomDatagrid';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withWidth from '@material-ui/core/withWidth';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "lightgray"
    },
    botonNuevo : {
        marginLeft : "10px"
    }
});

function dibujaNodosHijos(nodo,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => {
                    checknodoabierto(item);
                    return (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px', width: 'max-content'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button title={ translate('traducciones.titulos.selectNodo')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <VisibilityIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                        </div>
                        }
                    </div>
                )})
                }
            </div>
        )
    }
}

const IndicesList = ({ width,...props }) => {      
    const permisos = props.permissions ? props.permissions.Indices : null;    
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }


    const classes = useStyles();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [loadinga, setLoadinga] = useState(false);
    const [openAdd, setopenAdd] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [indiceSeleccionado, setindiceSeleccionado] = useState(null);
    const [create] = useCreate('indices');
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const refresh = useRefresh();
    const [dataIndices, setDataIndices] = useState([]);

    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);

    const notify = useNotify();


    const [openModalAddIndice, setOpenModalAddIndice] = useState(false);

    

    const [randomUpdate, setRandomUpdate] = useState(null);

    const dataProvider = useDataProvider();

    const addNode = () => {
        setOpenModalAddIndice(true);
    }

    const individual = () => {
        setOpenModalAddIndice(false);
        setopenAdd(true);
    }

    const viewNode = (nodoPadre) => {
        dataProvider.getList('indices', { IdNode: nodoPadre != null ? nodoPadre.RutaNodo : "/"})
            .then(({ data }) => {
                setnodoPadreElemento(nodoPadre);
                setDataIndices(data);
            })
            .catch(error => {
        });   
    }

    const convertKeyToIndice = (data) => {
        let ids = [];
        const dataKey = [];      
        if (data) {
          ids = data.map((elemento) => {
            dataKey[elemento.id] = elemento;
            return elemento.id;
          });
          return ({ dataKey, ids });
        }
        return ({});
    };

    const handleCloseClick = () => {
        setopenAdd(false);
        setopenDelete(false);
        setOpenModalAddIndice(false);
    }

    if(loadinga){
        return <Loading />
    }

    const deleteRecord = (record) => {
        setindiceSeleccionado(record);
        setopenDelete(true);
    }

    const handleSubmitDelete = async values => {
        setLoadinga(true);        
        deleteOne('indices', indiceSeleccionado.id, indiceSeleccionado,{
            onSuccess: ({ data }) => {
                setDataIndices(data.data);
                setopenDelete(false);
                setLoadinga(false);
                refresh();
            },
            onFailure: ({ error }) => {
            }
        });
        setLoadinga(false);  
        
    };


    const handlePlantilla = async values => {
        if(values== undefined || values.plantilla == undefined){
            //return { returnPromise: true }
        }
        setLoadinga(true);
        dataProvider.getOne('tagsTemplate', { id: values.plantilla })
            .then(({ data }) => {
                var datos = data.data.templateFields;
                var keywords = [];
                datos.forEach(function (record, index) { 
                    keywords.push({
                        "IdIndice": 1,
                        "IdNodo": nodoPadreElemento.RutaNodo,
                        "NombreIndice": datos[index],
                        "Tipo": 1,
                        "Formato": "",
                        "Mascara": "",
                        "Minimo": 0,
                        "Maximo": 999,
                        "IdTabla": 0,
                        "Longitud": 999,
                        "Requerido": false,
                        "Multiple": false,
                        "Unico": false,
                        "Verificar": false,
                        "Repetido": false,
                        "IdDiccionario": 1,
                        "IdModel" : values.plantilla,
                        "Biometrico": 1
                    });
                });


                var repetidas = false;
                keywords.forEach(function (nuevas, index) { 
                    dataIndices.forEach(function (viejas, index) { 
                            if(nuevas.NombreIndice == viejas.NombreIndice){
                                repetidas = true;
                            }
                    });
                });
                if(repetidas){
                    setLoadinga(false);
                    notify("traducciones.error.indicePlantillaRepetido", 'error');
                    return { returnPromise: true }
                }


                if(keywords.length > 0){
                    create(
                        "indices",
                        { 
                            "Keywords": keywords
                        },{
                            onSuccess: ({ data }) => {
                                setLoadinga(false);
                                setDataIndices(data.data);
                                handleCloseClick();
                            },
                            onFailure: ({ error }) => {
                                setLoadinga(false);
                            }
                        }
                    );
                }
            })
            .catch(error => {
        });  
        return { returnPromise: true }
    }

    const handleSubmitUpdate = async values => {
        var keywords = [];
        values.NombreIndice.forEach(function (record, index) { 
            keywords.push({
                "IdIndice": values.IdIndice[index],
                "IdNodo": nodoPadreElemento.RutaNodo,
                "NombreIndice": values.NombreIndice[index],
                "Tipo": values.Tipo[index],
                "Formato": "",
                "Mascara": values.Mascara ? values.Mascara[index] : "",
                "Minimo": values.Minimo[index],
                "Maximo": values.Maximo[index],
                "IdTabla": 0,
                "Longitud": values.Longitud[index],
                "Requerido": values.Requerido[index],
                "Multiple": values.Multiple[index],
                "Unico": values.Unico[index],
                "Verificar": values.Verificar[index],
                "Repetido": values.Repetido[index],
                "IdDiccionario": 1,//values.IdDiccionario[index],
                "Biometrico": 1,//values.Biometrico[index]
            });
        });
        setLoadinga(true);
        create(
            "indices",
            { 
                "Keywords": keywords,
                "function" : "update"
            },
            {
                onSuccess: ({ data }) => {
                    setDataIndices(data.data);
                    setopenAdd(false);
                    setLoadinga(false);
                    //refresh();
                },
                onFailure: ({ error }) => {
                    setLoadinga(false);
                    //notify(error.message, 'error');
                }
            }
        );
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }

    const handleSubmit = async values => {
        //setLoadinga(true);

        var repetidas = false;
        dataIndices.forEach(function (viejas, index) { 
                if(values.indiceName == viejas.NombreIndice){
                    repetidas = true;
                }
        });
        if(repetidas){
            setLoadinga(false);
            notify("traducciones.error.indiceRepetido", 'error');
            return { returnPromise: true }
        }


        create(
            "indices",
            { 
                "Keywords": [
                    {
                        "IdIndice": 1,
                        "IdNodo": nodoPadreElemento.RutaNodo,
                        "NombreIndice": values.indiceName,
                        "Tipo": 1,
                        "Formato": "",
                        "Mascara": "",
                        "Minimo": 0,
                        "Maximo": 999,
                        "IdTabla": 0,
                        "Longitud": 999,
                        "Requerido": false,
                        "Multiple": false,
                        "Unico": false,
                        "Verificar": false,
                        "Repetido": false,
                        "IdDiccionario": 1,
                        "Biometrico": 1
                    },
                ]
            },
            {
                onSuccess: ({ data }) => {
                    setDataIndices(data.data);
                    setopenAdd(false);
                    setLoadinga(false);
                    //refresh();
                },
                onFailure: ({ error }) => {
                    setLoadinga(false);
                    //notify(error.message, 'error');
                }
            }
        );
    };

    return (
        <Create {...props} title={ translate('traducciones.titulos.gestionDeIndices')} >
            <SimpleForm {...props} toolbar={ null }  >
            <div fullWidth>
                
                <div style={{ padding: '10px'}}>       
                    <Query type="getList" resource="nodos" payload={ nodoPadreElemento }>
                        {({ data, loading, error }) => {
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }   
                           
                            const dataGridValues2 = {
                                ...convertKeyToIndice(dataIndices),
                                total: dataIndices.lenght,
                                records : dataIndices
                            };
                            return (                                
                                <div  className="row">
                                    <div  className="col-md-4 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                    {   data.map(
                                        (item) => {
                                            checknodoabierto(item);
                                            return (
                                            <div>
                                                <div className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button title={ translate('traducciones.titulos.selectNodo')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <VisibilityIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                                                </div>
                                                }
                                            </div>
                                        )})
                                    }
                                    </div>
                                    {
                                        nodoPadreElemento != null ? (
                                    <div  className="col-md-8 col-md-custom">
                                    
                                    <div>
                                    { checkPermiso('AgregarIndices') && <Button className="MuiButton-containedPrimary" title={ translate('traducciones.titulos.addNodosPrincipal')} onClick={() => addNode()} style={{ marginLeft: "18px", padding: "8px"}}><AddIcon/> {translate("traducciones.titulos.nuevoIndice")}</Button> }
                                    </div>
                                        <div className="customDataGrid">
                                            <SimpleForm save={ handleSubmitUpdate } toolbar= { checkPermiso('EditarIndices') ? <SaveButton style={{ marginTop: "20px", marginLeft: "15px"}} /> : false } >                                            
                                                <CustomDatagrid dataGridValues={dataGridValues2} >
                                                    <TextInput tdstyle={{ minWidth: '200px'}} disabled = { !checkPermiso('EditarIndices') }  source="NombreIndice"  label="traducciones.campos.indice" />
                                                    <SelectInput source="Tipo" disabled = { !checkPermiso('EditarIndices') } label="traducciones.campos.tipo" choices={[
                                                        { id: '1', name: translate('Alfa Numerico') },
                                                        { id: '2', name: translate('Numérico') },
                                                        { id: '3', name: translate('Alfabético') },
                                                        { id: '4', name: translate('Fecha') },
                                                        { id: '5', name: translate('Hora') },
                                                        { id: '6', name: translate('Email Automático') },
                                                    ]} />
                                                    <TextInput tdstyle={{ minWidth: '200px'}} disabled = { !checkPermiso('EditarIndices') } source="Mascara" label="traducciones.campos.mascara" />
                                                    <NumberInput tdstyle={{ minWidth: '100px'}} disabled = { !checkPermiso('EditarIndices') }  source="Minimo" label="traducciones.campos.minimo" />
                                                    <NumberInput tdstyle={{ minWidth: '100px'}} disabled = { !checkPermiso('EditarIndices') } source="Maximo" label="traducciones.campos.maximo" />
                                                    <NumberInput tdstyle={{ minWidth: '100px'}} disabled = { !checkPermiso('EditarIndices') } source="Longitud" label="traducciones.campos.longitud" />
                                                    <BooleanInput  source="Requerido" disabled = { !checkPermiso('EditarIndices') } label= "traducciones.campos.requerido" />
                                                    <BooleanInput  source="Multiple" disabled = { !checkPermiso('EditarIndices') } label= "traducciones.campos.multiple" />
                                                    <BooleanInput  source="Unico" disabled = { !checkPermiso('EditarIndices') }label= "traducciones.campos.unico" />
                                                    <BooleanInput  source="Verificar" disabled = { !checkPermiso('EditarIndices') } label= "traducciones.campos.verificar" />
                                                    <BooleanInput  source="Repetido" disabled = { !checkPermiso('EditarIndices') } label= "traducciones.campos.repetido" />
                                                    {/* <TextField  source="IdDiccionario" label= "traducciones.campos.diccionario" /> */ }
                                                    { /* <TextField  source="Biometrico" label= "traducciones.campos.biometrico" /> */ }
                                                    { checkPermiso('EliminarIndices') && <Button action= { deleteRecord } icon={ <DeleteIcon /> } /> }
                                                    <TextInput type="hidden" source="IdIndice"  label="" />
                                                </CustomDatagrid>
                                            </SimpleForm>
                                        </div>                                                                    
                                    </div>
                                    ) : null }
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </div>     
            <Dialog
                fullWidth
                open={openAdd}>
                <DialogTitle>{ translate('traducciones.titulos.nuevoIndice')}</DialogTitle>
                <FormWithRedirect
                resource="indices"
                save={handleSubmit}
                render={({
                    handleSubmitWithRedirect,
                    pristine,
                    saving
                }) => (
                    <>
                    <DialogContent>
                        <TextInput style={{ width: '100%'}} source="indiceName" label="traducciones.campos.indiceNombre" validate={required()} />
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            resource="indices"
                            label="ra.action.add"
                            handleSubmitWithRedirect={
                                handleSubmitWithRedirect
                            }
                            pristine={pristine}
                            saving={saving}
                        />
                        <Button onClick={handleCloseClick} >
                            { translate('ra.action.cancel')}
                        </Button>
                    </DialogActions>
                    </>
                )}
                />
                </Dialog>   
            <Dialog
                    fullWidth
                    open={openDelete}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarIndice')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDelete}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarIndiceConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>     


                <Dialog 
                    fullWidth
                    open={openModalAddIndice}>
                    <DialogTitle>{ translate('traducciones.titulos.agregarIndice')}</DialogTitle>
                    <DialogContent>
                        <SimpleForm save={handlePlantilla} toolbar = { <Toolbar> <SaveButton style={{ marginRight : "10px !important "}} label ="traducciones.titulos.plantillaSeleccionada" /><Button2  label="traducciones.botones.indiceIndividual" className={ classes.botonNuevo } onClick={() => individual() } /></Toolbar>}>
                            <ReferenceInput label="traducciones.campos.plantillas" source="plantilla" reference="plantillas">
                                <AutocompleteInput optionText="ModelName" optionValue="IdModel" validate={required()} />
                            </ReferenceInput> 
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseClick} >
                            { translate('ra.action.close')}
                        </Button>
                    </DialogActions>
                </Dialog>     

            </SimpleForm>
        </Create>
)};

export default withWidth()(IndicesList);
