import React from 'react';
import { 
    Edit, 
    TextInput, 
    SimpleForm, 
    Toolbar,
    SaveButton,
    required, 
    useEditController,
    useTranslate,
    useDataProvider,
    NumberInput,
    DateInput,
    useRefresh,
    Loading,
    SelectInput
} from 'react-admin';
import { useState  } from 'react';
import Button from '@material-ui/core/Button';
import DocumentPreview from '../Components/DocumentPreview';
import withWidth from '@material-ui/core/withWidth';
import { useHistory } from 'react-router-dom';

const EditToolbar = ({ onCancel, ...props }) => {
    
    const translate = useTranslate();

    return (
       <Toolbar {...props}>
           <div>
                <SaveButton label="ra.action.save" {...props} />
                <Button onClick={onCancel} style={{position: 'absolute', right: '26px'}}>{translate('ra.action.cancel')}</Button>
           </div>
       </Toolbar>
)};

const DocumentIndexar = ({ width,onCancel, onSuccessOk, ...props }) =>
{

    const { record } = useEditController(props);
    const [dataIndices, setDataIndices] = useState(null);
    const [serviceCall, setServiceCall] = useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const history = useHistory(); 
    
    const translate = useTranslate();

    var ubicacion = ""
    
    if(record != undefined && record.data != undefined){
        ubicacion = record.data.IdNodePath.replace("/", " / ").slice(0, -1);
    }else{
        return <Loading />
    }


    if(record && record.data && dataIndices ==  null && !serviceCall){        
        setServiceCall(true);
        dataProvider.getList('indices', { IdNode: record.data ? record.data.IdNode : "/", Prediction: true, IdDocumentTmp: record.id })
                .then(({ data }) => {
                    setDataIndices(data);
                })
                .catch(error => {
            });  
    }
    const back = () => {
        history.push({
            pathname: '/documentos',
            state: {},
        });
        refresh();
    } 

    return (
        <Edit {...props} undoable={false} onSuccess={ onSuccessOk }>
            <SimpleForm  toolbar={<EditToolbar onCancel={back} />}>
                <div fullWidth>
                    <div style={{ textAlign: 'center', width: '100%'}}>
                        <div className="titleClass">{translate('traducciones.titulos.indexarDocumento')}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            { record ? (
                            <DocumentPreview document = { record.data } />
                            ) : null 
                            }
                        </div>
                        <div className="col-md-6">
                            <div style={{
                                textAlign: 'center',  padding: '5px',
                                backgroundColor: '#849e88',
                                color: 'white',
                                fontSize: '16px'
                            }
                            } ><b>{translate('traducciones.titulos.ubicacion')} : </b> {  ubicacion }</div>
                            { dataIndices ? (
                                <div className="row">
                                    {   dataIndices.map(
                                        (item) => {
                                            return (
                                            <div className="col-md-6">
                                                { item.Tipo == 1 && !item.ValoresTabla ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice  }  />) : null }

                                                { item.Tipo == 1 && item.ValoresTabla ? (
                                                    <div>
                                                        <SelectInput style={{ width: "100%" }} name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } />
                                                    </div>
                                                ) : null }

                                                { item.Tipo == 2 ? (<NumberInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.Tipo == 3 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.Tipo == 4 ? (<DateInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "]" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.Tipo == 5 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.Tipo == 6 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                            </div>
                                        )})
                                    }    
                                </div>
                                ) : null 
                            }
                        </div>
                    </div>
                    
                </div>
            </SimpleForm>
        </Edit>
    )
};

export default withWidth()(DocumentIndexar);