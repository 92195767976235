import * as React from 'react';
import {
    Datagrid,
    List,
    TextField,
    BooleanField,
    TopToolbar,
    CreateButton,
    DeleteButton,    
    useRefresh,
    useRedirect,
    EditButton,
    useNotify,
    useTranslate,
    Pagination
} from 'react-admin';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import GrupoCreate from './GrupoCreate';
import GrupoEdit from './GrupoEdit';
import withWidth from '@material-ui/core/withWidth';
import MobileGrupoList from './Responsive/MobileGrupoList';


const GruposActions = ({ 
    basePath,
    checkPermiso
    }) => {        
    return (
    <TopToolbar>
        { checkPermiso('AgregarGrupos') && <CreateButton basePath={basePath} label="traducciones.titulos.nuevoGrupo"  /> }
    </TopToolbar>
)};

const GruposList = ({ width,...props }) => {

    const permisos = props.permissions ? props.permissions.Grupos : null;    
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }

    const refresh = useRefresh();
    const redirect = useRedirect();
    const notify = useNotify();
    const translate = useTranslate();

    const handleClose = () => {
        props.history.push("/grupos");
    };

    const onSuccess = ({ data }) => {
        redirect('/grupos');
        notify('traducciones.notificaciones.addGrupoOk')
        refresh();
    };

    const onSuccessEdit = ({ data }) => {
        redirect('/grupos');
        notify('traducciones.notificaciones.editGrupoOk')
        refresh();
    };

    const Empty = () => (
        <div  style={{ textAlign: 'center', margin: '15px', backgroundColor: 'white', paddingBottom: '30px'}}>
            <p></p>
            <img src={"grupo.png"} style={{width: '15vw'}} />
            <p>            
            { translate('traducciones.generales.gruposTexto1') }
            </p>
            <p>
            { translate('traducciones.generales.gruposTexto2') }
            </p>
            <p>
            <b>{ translate('traducciones.generales.step1') }</b> { translate('traducciones.generales.grupostep1') }
            </p>
            <p>
            <b>{ translate('traducciones.generales.step2') }</b> { translate('traducciones.generales.grupostep2') }
            </p>
            <p>
            <b>{ translate('traducciones.generales.step3') }</b> { translate('traducciones.generales.grupostep3') }   
            </p>
            <p></p>
            { checkPermiso('AgregarGrupos') && <CreateButton label='traducciones.titulos.nuevoGrupo'></CreateButton> }
        </div>
    );

    const ListPagination = props => <Pagination rowsPerPageOptions={[5,10, 25, 50, 100]} {...props} />;

    return (
        <div>       
            <List
                {...props}
                title={translate('traducciones.titulos.gestionDeGrupos')}
                actions = { <GruposActions checkPermiso= { checkPermiso } /> }
                sort={{ field: 'GrupoNombre', order: 'ASC' }}
                registro={ props.record} 
                empty= { <Empty /> }
                bulkActionButtons={false}
                pagination={<ListPagination />}
                >
            { width != "xs" ? (   
            <Datagrid isRowSelectable={record => record.id < 1} >
                    <TextField source="GrupoNombre" label= "traducciones.campos.nombreDeGrupo" />
                    <BooleanField  source="Permissions.Anotaciones" label= "traducciones.permisos.anotaciones" />
                    <BooleanField  source="Permissions.Imprimir" label= "traducciones.permisos.imprimir" />
                    <BooleanField  source="Permissions.Exportar" label= "traducciones.permisos.exportar" />
                    <BooleanField  source="Permissions.Importar" label= "traducciones.permisos.importar" />
                    <BooleanField  source="Permissions.Digitalizar" label= "traducciones.permisos.digitalizar" />
                    <BooleanField  source="Permissions.Enviar" label= "traducciones.permisos.enviar" />
                    <BooleanField  source="Permissions.Sin_marca_de_agua" label= "traducciones.permisos.sinmarcaagua" />
                    { checkPermiso('EditarGrupos') && <EditButton label="" className="actionButton" /> }
                    { checkPermiso('EliminarGrupos') && <DeleteButton label="" undoable={false} confirmTitle={ translate('traducciones.modal.eliminarGrupo')} confirmContent={ translate('traducciones.modal.eliminarGrupoConfirm')}></DeleteButton> }
                </Datagrid>
            ) : (
                <MobileGrupoList checkPermiso= { checkPermiso } />
            )
            }
            </List>
            <Route
            path="/grupos/create"
            render={() => (
                <Drawer anchor="right" open onClose={handleClose}>
                    <GrupoCreate {...props} onCancel={handleClose} onSuccessOk= { onSuccess } />
                </Drawer>
            )}
            />
            <Route path="/grupos/:id">
            {({ match }) => {
                const isMatch = match && match.params && match.params.id !== "create";
                return (
                <Drawer open={isMatch} anchor="right" onClose={handleClose}>
                    {isMatch ? (
                    <GrupoEdit
                        id={match.params.id}
                        record={match.params}
                        onCancel={handleClose}
                        onSuccessOk= { onSuccessEdit }
                        {...props}
                    />
                    ) : null}
                </Drawer>
                );
            }}
        </Route>
    </div>
)};

export default withWidth()(GruposList);
