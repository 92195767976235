import * as React from 'react';
import { useState  } from 'react';
import {
    FormWithRedirect,
    TextInput ,
    useDelete,
    useTranslate,
    SimpleForm,  
    Loading, 
    Error, 
    Create,
    useRefresh,
    Query, 
    NumberInput ,
    SaveButton,
    DateInput,
    useDataProvider,
    Pagination,
    useNotify,
    SelectInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import withWidth from '@material-ui/core/withWidth';
import CardResultado from './Responsive/CardResultado';
import ExternalViewer from '../Libs/ExternalViewer/ExternalViewer';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TableResultado from '../Components/TableResultado';

/*
Componente dedicado a la busqueda por campos 
*/

//Objeto principal para creacion de classes css del objeto de manera Dinamica.
const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        width: 'max-content'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    }
});

/*
Funcion recursiva que crear la visualizacion y opciones de los nodos hijos
Esta funcion recibe como parametros:
nodo : nodo padre,
clases : objeto de clases css dinamico,
addnode : funcion para seleccionar cada nodo,
translate : funcion de react admin declarada en la clase principal para las traducciones
nodoPadreelemento: nodo seleccionado principal,
colapsar: funcion para colapsar o no el nodo
checknodoabierto: funcion para verificar el estado de colapso del nodo que se esta dibujando
*/
function dibujaNodosHijos(nodo,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => {
                    checknodoabierto(item);
                    return (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button title={ translate('traducciones.titulos.consultarCampos')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <SearchIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                        </div>
                        }
                    </div>
                )})
                }
            </div>
        )
    }
}

const BusquedaCampos = ({ width, ...props }) => { 
    
    /*
    Props. permisos es un arreglo de permisos que devuelve React Admin, en cada vista (funcion dentro del Auth Provider)
    */
    const permisos = props.permissions ? props.permissions.ConsultadeDocumentos : null;  
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }

    const classes = useStyles();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [loadinga, setLoadinga] = useState(false);
    const [openNoResult, setOpenNoResult] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [openDocument, setopenDocument] = useState(false);    
    const [indiceSeleccionado, setindiceSeleccionado] = useState(null);
    const [document, setDocument] = useState(null);
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const refresh = useRefresh();
    const [dataIndices, setDataIndices] = useState([]);
    const [dataSearch, setDataSearch] = useState([]);
    const [metas, setMetas] = useState(null);
    const [visor, setVisor] = useState(null); 
    const notify = useNotify();

    const [randomUpdate, setRandomUpdate] = useState(null);
    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);
    const [openDeleteDocumento, setOpenDeleteDocumento] = useState(false);

    const dataProvider = useDataProvider();

    //Paginación///////////////////////////////

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [order, setOrder] = useState("");
    const [sort, setSort] = useState(true);

    const changePage = (nPage) => {
        setPage(nPage);
        reloadPages(nPage,perPage);
    }
    const ChangePerPage = (rows) => {
        setPage(1);
        setPerPage(rows);   
        reloadPages(1,rows);
    }

    const cambiaOrden = (column) => {
        setOrder(column);
        reloadPages(null,null,!sort,column);
        setSort(!sort);
    }

    const reloadPages = (npage,nperpage,nsort,norder) => {
        dataProvider.getList('queryCampos', { 
            IdNode: nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", 
            PageNumber : npage? npage : page,
            DocumentsNumber : nperpage ? nperpage : perPage,
            OrderField : norder ? norder : order,	
            AscendingOrder : nsort != null ? nsort : sort,
            Metadata: metas,
        })
            .then((data) => {
                if(data != null && data.data.length == 0){

                    setOpenNoResult(true);
                }else{
                    if(data != null && data.data.length > 0){
                        setDataSearch(data);  
                        setOpenResult(true);
                    }
                }
            })
            .catch(error => {
        });   
    }

    ///////////////////////////////////////////

    const viewNode = (nodoPadre) => {
        dataProvider.getList('indices', { IdNode: nodoPadre != null ? nodoPadre.RutaNodo : "/"})
            .then(({ data }) => {
                setPage(1);
                setnodoPadreElemento(nodoPadre);
                setDataIndices(data);
            })
            .catch(error => {
        });   
    }

    const handleCloseClick = () => {
        setOpenNoResult(false);
        setOpenResult(false);
        setopenDelete(false);
    }

    if(loadinga){
        return <Loading />
    }

    const handleSubmitDelete = async values => {
        setLoadinga(true);        
        deleteOne('indices', indiceSeleccionado.id, indiceSeleccionado,{
            onSuccess: ({ data }) => {
                setDataIndices(data.data);
                setopenDelete(false);
                setLoadinga(false);
                refresh();
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
            }
        });
        setLoadinga(false);  
        
    };


    const handleSubmitSearch = async values => {

        var campos = Object.entries(values);
        var meta = [];
        campos.forEach(function (record, index) { 
            if(record[1] != undefined && record[1] != null && record[1] != ""){
                meta.push(
                    {
                        "IdDocumento": "",
                        "IdIndice": record[0].replace('campo-', ''),
                        "ValorLlave": record[1]
                    }
                );
            }
        });

        setMetas(meta);

        dataProvider.getList('queryCampos', { 
            IdNode: nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", 
            PageNumber : 1,
            DocumentsNumber : 10,
            OrderField : "",	
            AscendingOrder : true,
            Metadata: meta,
        })
            .then((data) => {
                if(data != null && data.data.length == 0){

                    setOpenNoResult(true);
                }else{
                    if(data != null && data.data.length > 0){
                        setDataSearch(data);  
                        setOpenResult(true);
                    }
                }
            })
            .catch(error => {
        });   
    }

    const consultaDocumento = (elem) => {
        /*
        La funcion consulta documento es la encargada de llamar a la api de maximage para obtener la informacion del documento
        y con esa informacion en base 64, llamar al visor de LeadTools
        */
       //Llamado a la api de documentos
        dataProvider.getOne('documento', { IdDocument: elem.IdDocument})
            .then(({ data }) => {
                data.data.IdDocument = elem.IdDocument;
                setDocument(data.data);
                setOpenResult(false);
                setopenDocument(true);
                /*
                Instancia del visor de LeadTools, en este caso el objeto del aplicativo se llama HTML5Demo, porque asi se llamaba
                en el ejemplo de la documentacion general.
                DocumentViewerDemo, es el objeto del visor en si.
                y la funcion DocumentViewerDemoApp() lo inicializa
                */
                var app = new window.HTML5Demos.DocumentViewerDemo.DocumentViewerDemoApp();

                //Luego de inicializarlo, guardamos ese objeto en react para manipularlo en el componente.
                setVisor(app);

                /*
                Luego de instanciar el objeto del visor enviaremos ciertos parametros hacia el visor
                para que pueda interpretar esta informacion, el objeto que creamos para enviar la informacion 
                lo llamaremos fileExt, haciendo referencia a que es un documento externo al applicativo.
                */

                //Antes de enviar el resultado del servicio al objeto fileExt, adjuntaremos las traducciones correspondientes.
                data.data.traducciones = {
                    'cargandoDocumento' : translate('traducciones.visor.cargandoDocumento'),
                    'verificando' : translate('traducciones.visor.verificando'),
                    'abriendoDocumento' : translate('traducciones.visor.abriendoDocumento'),
                }

                data.data.userName= localStorage.getItem("userName");

                //Luego de cargar los parametros al objeto data.data (que es el objeto resultante del servicio), lo enviaremos al visor mediante el objeto fileExt
                app.fileExt = data.data;

                //Corremos el visor para que interprete los datos, esta interpretacion se puede seguir en el archivo public/Main/Main.js
                app.run();        
            })
            .catch(error => {
        });
    } 

    /*
    Esta funcion sera la encargada de obtener las anotaciones desde el visor y guardarlas mediante las apis de MAxImage
    */
    const saveAnotations = ()=>{
        /*
        Ejecutaremos la funcion getAnnotations(), funcion desarrollada para obtener las antoaciones del visor en 
        formato XML, para poder fuardarlas en la api. Esta funcion se encuentra en public/Main/Main.js en la linea 2266
        */
        var anotations = visor.getAnnotations();
        if(anotations.length > 0){
            dataProvider.update('anotaciones', { DocumentId: document.IdDocument,Annotations:anotations})
                .then(({ data }) => {
                    notify('traducciones.notificaciones.actualizaAnotacionesOK')
                })
                .catch(error => {
            });   
        }
    }

    const closeExternal = () =>{
        setOpenResult(true);
        setopenDocument(false);
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }


    const deleteDocument = (doc) =>{
        setDocument(doc);
        setOpenDeleteDocumento(true);
    }


    const handleSubmitDeleteDocument = async values => {
        deleteOne('documento', document.IdDocument, document,{
            onSuccess: ({ data }) => {
                changePage(page);
                setOpenDeleteDocumento(false);
                setLoadinga(false);
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
            }
        });        
    };

    const handleCloseClick2 = () => {
        setOpenDeleteDocumento(false);
    }

    var count = 0;

    return (
        <Create {...props} title={ translate('traducciones.titulos.busquedaCampos')} >
            <SimpleForm {...props} toolbar={ null }  >

            {/* 
            External Viewer es el componente desarrollado para poder respresentar el html del visor de LeadTools en React.
            Este componente contiene el html codificado para react, obtenido desde el archivo Libs/ExternalViewer/index.html
            en este caso enviaremos al componente dos funciones locales:
            closeExternal, que cerrara el visor
            saveAnotations, que guardara las anotaciones cuando el usuario lo indique.
            */}
            
            { openDocument ? (     
            <ExternalViewer closeExternal = { closeExternal } saveAnotations= {saveAnotations} />
            ):(

            <div fullWidth>
                
                <div style={{ padding: '10px'}}>       
                    <Query type="getList" resource="nodos" payload={ nodoPadreElemento }>
                        {({ data, loading, error }) => {
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-5 col-md-custom" style={{ overflowX: "auto", padding: "10px"}}>
                                    {   data.map(
                                        (item) => {
                                            checknodoabierto(item);
                                            return (
                                            <div>
                                                <div className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button title={ translate('traducciones.titulos.consultarCampos')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <SearchIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                                                </div>
                                                }
                                            </div>
                                        )})
                                    }
                                    </div>
                                    {
                                        nodoPadreElemento != null ? (
                                    <div  className="col-md-7 col-md-custom">
                                        { dataIndices.length > 0 ? (
                                        <div>
                                            <div>
                                                {translate('traducciones.titulos.camposResultados')}
                                            </div>
                                            <SimpleForm save={ handleSubmitSearch } toolbar= { <SaveButton label={translate('traducciones.botones.consultar')} icon= { <SearchIcon /> } /> } >
                                                <div className="row" fullWidth>
                                                {   dataIndices.map(
                                                        (item) => (
                                                            <div className="col-md-6">
                                                                { item.Tipo == 1 && !item.ValoresTabla ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true }  label={ "Campo " + item.NombreIndice } source={item.IdIndice  }  />) : null }

                                                                { item.Tipo == 1 && item.ValoresTabla ? (
                                                                    <div>
                                                                        <SelectInput style={{ width: "100%" }} name={ "campo-" + item.IdIndice + "" } resettable={ true }  label={ "Campo " + item.NombreIndice } source={item.IdIndice }  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } />
                                                                    </div>
                                                                ) : null }

                                                                { item.Tipo == 2 ? (<NumberInput style={{ width: "100%" }} name={ "campo-" + item.IdIndice + "" } resettable={ true } label={ "Campo " + item.NombreIndice } source={item.IdIndice } value="" defaultValue="" />) : null }
                                                                { item.Tipo == 3 ? (<TextInput style={{ width: "100%" }} type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } label={ "Campo " + item.NombreIndice } source={item.IdIndice } value="" defaultValue="" />) : null }
                                                                { item.Tipo == 4 ? (<DateInput style={{ width: "100%" }} name={ "campo-" + item.IdIndice + "]" } resettable={ true } label={ "Campo " + item.NombreIndice } source={item.IdIndice } value="" defaultValue="" />) : null }
                                                                { item.Tipo == 5 ? (<TextInput style={{ width: "100%" }} type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } label={ "Campo " + item.NombreIndice } source={item.IdIndice } value="" defaultValue="" />) : null }
                                                                { item.Tipo == 6 ? (<TextInput style={{ width: "100%" }} type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } label={ "Campo " + item.NombreIndice } source={item.IdIndice } value="" defaultValue="" />) : null }
                                                            </div>
                                                        ))
                                                    }    
                                                </div>   
                                            </SimpleForm>  
                                        </div>                      
                                        ) : (
                                            <div style={{marginTop:'20%'}}><WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.notificaciones.noHayCampos')}</div>
                                        )  
                                        }       
                                    </div>
                                    ) : null }
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </div>   
            
            ) }



            <Dialog
                fullWidth
                open={openNoResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.modal.noResults')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>   

            <Dialog
                    fullWidth
                    open={openDeleteDocumento}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarDocumento')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDeleteDocument}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarDocumentoConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick2} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>  


            <Dialog
                fullWidth
                open={openResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    { width != "xs" ? (
                    <TableResultado 
                        dataSearch= { dataSearch } 
                        consultaDocumento = { consultaDocumento } 
                        deleteDocument = { deleteDocument} 
                        cambiaOrden = { cambiaOrden } 
                        order = {order}
                        sort = { sort }
                        checkPermiso = { checkPermiso }
                        perPage= {perPage} 
                        setPage= {changePage} 
                        setPerPage = {ChangePerPage} 
                        page={page}
                    />
                    ) : ( 
                        <div>
                        {
                        dataSearch && dataSearch.data && dataSearch.data.map(elem => {
                                count++;
                                return (  
                                    <CardResultado dataSearch= {dataSearch} i = {count } cambiaOrden= {cambiaOrden} order= {order} sort={sort} elem = {elem} />
                                )                
                            })
                         }
                        <Pagination perPage= {perPage} setPage= {changePage} setPerPage = {ChangePerPage} page={page} total= {dataSearch.total} />
                        </div>) }   
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>     

            <Dialog
                    fullWidth
                    open={openDelete}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarNodo')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDelete}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarNodoConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>         

            </SimpleForm>
        </Create>
)};

export default withWidth()(BusquedaCampos);
