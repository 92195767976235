import React from 'react';
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  Checkbox,
  Card,
} from '@material-ui/core';
import {
  useRedirect, useTranslate} from 'react-admin';
  import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import withWidth from '@material-ui/core/withWidth';
import CardHeader from '@material-ui/core/CardHeader';

/*
Este es un archivo que crear la tabla de datos de manera personalizada, es decir creamos el componente CustomDatagrid,
Ya que las funciones especificas del Data Grid de React-Admin puede ser insuficiente

*/


const useStyles = makeStyles({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem 0',
    borderRadius : '16px',
    marginBottom: '15px',
    boxShadow: '1px 3px 7px #3b824b',
    paddingBottom : "10px",
    marginLeft: "-20px",
    marginRight: "-20px",
  },
  cardTitleContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
  },
  cardContentRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0.5rem 0',
  },
  cardHeader : {
    backgroundColor: '#525b59',
    paddingBottom: '10px',
    paddingTop: '10px',
    color: 'white'
  },
  divLabel : {
    width: '40%',
    float : "left",
    height : '60px',
    paddingTop : '20px'
  }
});

const CustomDatagrid = (propsDataGrid) => {
  const redirect = useRedirect();
  const classes = useStyles();
  //Listado de propiedades que seran enviadas como parametros al componente
  const { children, className, dataGridValues, } = propsDataGrid;
  const {
    dataKey,
    ids,
    total,
    rowsPerPage,
    setRowsPerPage,
    setNewPage,
    newPage,
  } = dataGridValues;

  //Funcion para cambiar de pagina
  const handleChangePage = (event, page) => {
    setNewPage(page);
  };

  //Funcion para seleccionar un item de la tabla en el caso que tenga el componente de tipo check
  const onToggleItem = (fila) => {
        if(fila.seleccionado){
          fila.seleccionado = false;
        }else{
          fila.seleccionado = true;
        }    
        return fila.seleccionado;
  }

  //Funcion para seleccionar todos en caso de que exista el selector total
  const onToggleAll = () => {
    ids.forEach(element => {
      dataGridValues.dataKey[element].seleccionado = true;
    });
  }

  //Funcion para cambiar la cantidad de resultados de la paginación
  const handleChangeRowsPerPage = (event) => {
    const vlrDecimal = parseInt(event.target.value, 10);
    setRowsPerPage(vlrDecimal);
    setNewPage(0);
  };

  const translate = useTranslate();

  var index = -1;

  return (
    <div>
    { 
    /*
    Verificaremos si es movil para dibujar o no una tabla o un conjunto de Cards
    */
    propsDataGrid.width != "xs" ? (
    <Card >
      <Table className={className + " customDataGrid"}>
        <TableHead>
          <TableRow>
            {
            /* Recorremos los componentes hijos de la tabla para crear las cabeceras, si existe la propiedad label dentro del componente tendremos ese titulo, 
            Si no dibujaremos el nombre del contenido.
            */
            children && children.map(({ props }) => {
            if(props?.type != "hidden")
            return  (
              <TableCell>
                {props && props.label ? translate(props.label) : props?.source}
              </TableCell>
            )
            }
            )            
          }
          </TableRow>
        </TableHead>
        <TableBody>
          {
          /*
            REcorreremos cada Hijo para dibujar su contenido. en el caso de que el componente hijo tenga la propiedad action,
            dibujaremos un boton, sino dibujaremos el componente en si, adjuntandole las propiedades que ya posee y seteando cual es el valor del elemento
          */
          ids && ids.map(id => {
            index++;
            return(
            <TableRow>
              {React.Children.map(children,
                child => {
                  if(child===null){
                    return null;
                  }
                  return(
                  <TableCell style= { child.props?.tdstyle ? child.props.tdstyle : child.props?.type == "hidden" ? { display : "none"} : null }>
                    { !child.props?.action ?                     
                      React.cloneElement(child, { record: dataKey[id], defaultValue: dataKey[id][child.props.source] != null && dataKey[id][child.props.source] != undefined && dataKey[id][child.props.source] != ""  ? dataKey[id][child.props.source] : 0, label: '', name : child.props.source + "[" + index + "]" }, (child.props.children ? child.props.children : null)) 
                     : <Button onClick={ () => { child.props.action(dataKey[id]) }}>
                          { child.props?.icon ? child.props.icon : null}                       
                     </Button>
                    }                    
                  </TableCell>
                )})}
            </TableRow>
          )}
          )
          }
        </TableBody>
      </Table>
      
    </Card>
    ) : (<div>

{
          
          ids && ids.map(id => {
            index++;
            return(
            <Card className = { classes.card }>
              <CardHeader
                        className={classes.cardHeader }
                        title={
                          <div className={classes.cardTitleContent} alignItems="center">
                                <span style={{ fontSize: 'large' }}>
                                índice
                                </span>
                            </div>
                        }
                    />
              
              <div style={{padding: "15px"}}>
              {React.Children.map(children,
                child => {
                  return(
                  <div style= { child.props.tdstyle ? child.props.tdstyle : child.props.type == "hidden" ? { display : "none"} : null }>
                    <div className={ classes.divLabel } >{child.props && child.props.label ? translate(child.props.label) : child.props.source}</div>
                    <div style={{width: "60%", float: "right", height: '60px'}}>
                    { !child.props.action ?                     
                      React.cloneElement(child, { record: dataKey[id], defaultValue: dataKey[id][child.props.source] != null && dataKey[id][child.props.source] != undefined && dataKey[id][child.props.source] != ""  ? dataKey[id][child.props.source] : 0, label: '', name : child.props.source + "[" + index + "]" }, (child.props.children ? child.props.children : null)) 
                     : <Button onClick={ () => { child.props.action(dataKey[id]) }}>
                          { child.props.icon ? child.props.icon : null}                       
                     </Button>
                    }   
                     </div>           
                  </div>
                  
                )})}
                </div>
            </Card>
          )}
          )
          }


    </div>)
        }
    </div>
  );
};


CustomDatagrid.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};


export default withWidth()(CustomDatagrid);