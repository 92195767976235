import portugueseMessages from "@henriko/ra-language-portuguese";

const customPortugueseMessages = {
    ...portugueseMessages,
    traducciones: {
        menu: {
            administracion : 'Administração',
            usuarios: 'Usuários',
            grupos: 'Grupos',
            estructura: 'Estrutura',
            nodos : 'Nodes',
            indices : 'Índices'
        },
        titulos:{
            nuevoUsuario : 'Novo Usuário',
            nuevoGrupo : 'Novo Grupo',            
            editarUsuario : 'Editar Usuário',
            editarGrupo : 'Editar Grupo',
            gestionDeGrupos : 'Gestão do Grupos',
            gestionDeUsuarios : 'Gestão do Usuários',
            addNodosHijos : 'Adicionar filhos a este nó',
            addNodosPrincipal : 'Adicionar um nó principal',
            deleteNodo : 'Exclua este nó',
            nuevoNodo : 'Novo Nó',
            nodoPadre : 'Nó Pai',
            gestionDeNodos : 'Gestão do Nó',
            gestionDeIndices : 'Gestão do Índices',
        },
        permisos: {
            anotaciones : 'Anotações',
            exportar : 'Exportar',
            importar : 'Importar',
            digitalizar : 'Digitalizar',
            imprimir : 'Imprimir',
            enviar : 'Enviar',
        },
        campos: {
            nombre : 'Nome',
            nombreDeGrupo : 'Nome do grupo',
            email : 'Correio eletrônico',
            rol : 'Papel do usuário',
            estadoPassword: 'Status da senha',
            estadoUsuario: 'Status do usuário',
            vigenciaPassword: 'Validade da senha (dias)',
            nodoNombre : 'Nome do Nó',
            indice : 'Índice',
            tipo : 'Tipo de índice',
            mascara : 'Máscara',
            minimo : 'Mínimo',
            maximo : 'Máximo',
            longitud : 'Comprimento',
            requerido : 'Obrigatório',
            multiple : 'Múltiplo',
            unico : 'Exclusivo',
            verificar : 'Verifica',
            repetido : 'Repetido',
            diccionario : 'Dicionário',
            biometrico : 'Biométrico',

        },
        notificaciones: {
            addUsuarioOk : 'Usuário criado com sucesso',
            editUsuarioOk : 'Usuário modificado com sucesso',
            addGrupoOk : 'Grupo criado com sucesso',
            editGrupoOk : 'Grupo modificado com sucesso',
        },
        modal : {
            eliminarGrupo : 'Excluir Grupo',
            eliminarGrupoConfirm : 'Tem certeza de que deseja excluir este grupo?',
            eliminarUsuario : 'Deletar Usuário',
            eliminarUsuarioConfirm : 'Tem certeza de que deseja excluir este usuário?',
            eliminarNodo : 'Excluir nó',
            eliminarNodoConfirm : 'Tem certeza de que deseja excluir este nó?'
        },
        roles : {
            administrador : 'Administrador',
            seguridad : 'Segurança',
            supervisor : 'Supervisor',
            operador : 'Operador',
            usuario : 'Usuário',
        },
        estadoPassword : {
            caducado : 'Tempo esgotado',
            vigente : 'Válido' ,
            noCaduca : 'Não expira' ,
            temporalCaduca : 'Temporário que expira' ,
            temporalNoCaduca : 'Temporário que não expira',
        }
    }
}

export default customPortugueseMessages;