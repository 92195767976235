import React from 'react';
import { useState  } from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    useTranslate,
    Pagination,
    DeleteButton,
    Loading, 
    Error, 
    useRefresh,
    Query, 
    Link,
    useDataProvider,
    ReferenceInput,
    AutocompleteInput,
    SimpleForm,
    SaveButton
} from 'react-admin';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';


const useStyles = makeStyles({
    oculto : {
        visibility : "hidden"
    }
});

const ListPagination = props => <Pagination rowsPerPageOptions={[5,10, 25, 50, 100]} {...props} />;


const BusquedaPredefinida = ({ width,...props }) => {

    const translate = useTranslate();

    return(
    <List
            {...props}
            title={translate('traducciones.titulos.busquedaPredefinida')}
            registro={ props.record} 
            bulkActionButtons={false}            
            actions = {null}
            pagination={<ListPagination />} >
                { width != "xs" ? (  
                <Datagrid isRowSelectable={record => record.id < 1} >
                    <TextField source="IdQuery" label= "traducciones.campos.idQuery" />
                    <TextField source="NombreBusqueda" label= "traducciones.campos.nombreQuery"/>
                    <EditButton label="" icon={ <PlayArrowIcon /> } className="actionButton" title= { translate("traducciones.titulos.ejecutarConsulta") } />
                </Datagrid>
                ) : (
                    <div></div>
                )
            }
        </List>
    )
}

export default withWidth()(BusquedaPredefinida);