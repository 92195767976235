import * as React from "react";
import { AppBar, UserMenu, useTranslate } from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

/*
Componente que crear un App Bar personalizado

https://marmelab.com/react-admin/doc/3.18/Theming.html#replacing-the-appbar

*/

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    bar: {
        background: "linear-gradient(121deg, rgb(117 125 133) 0%, rgb(159 159 159) 63%, rgb(49 155 66) 100%)",
        maxHeight: '150px;',
        top: 'auto',
        marginTop: '-5px',
        paddingTop: '95px'
    },
    tool: {
        minHeight: '35px;'
    },
    banner: {
        height: '90px;'
    },
    userName : {
        position : 'absolute',
        right : '20px',
        top : '95px'
    }
});

const Banner = () => (
    <div className={"banner"}>
    </div>
);

const Logo = () => (
    <div className={"logo"}>
        <img className={"logo-img"} src={"logoblanco.png"} alt={"MAXIMAGE"}/>
    </div>
);
  

  const CustomUserMenu = (props) => {
    const translate = useTranslate();
      return (
    <div style={{display: 'contents'}}>
        <div style={{ float: "left"}}>{translate('traducciones.login.bienvenido')} : { localStorage.getItem("userNombre") }  </div>
        <UserMenu {...props} label={props.usuario} children={
            <div style={{ padding: "10px", textAlign: 'center' }}> 
                {translate('traducciones.campos.usuario')}: { localStorage.getItem("userName") } 
            </div>} 
        >
        </UserMenu>
    </div>

)};

const CustomAppBar= ({ width, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    var userLoged = "";
    if(window.$conf_general != undefined && window.$conf_general['nomUser'] != undefined){
        userLoged =  window.$conf_general['nomUser'];
    }
    return (
        <div>
    <AppBar {...props} elevation={1} className= { classes.bar }  userMenu={<CustomUserMenu usuario={ userLoged} />}>
    <Banner className={classes.banner} />
        <Logo />
        <Toolbar className={classes.tool}>            
            <Typography variant="h6" id="react-admin-title" className={classes.title} />
        </Toolbar>
        { width != "xs" ? (
        <div style={{ position: 'absolute', right: '20px', top: '-40px'}}>
            <a href="#" style={{ color: 'white', textDecoration: 'none' }}>{translate('traducciones.login.ayuda')}</a>
            <a href="https://www.maximage-ds.com/contacto" target="_blank" style={{ marginLeft: '30px', color: 'white', textDecoration: 'none' }}>{translate('traducciones.login.contactenos')}</a>
        </div>
        ) : 
        (
        <div style={{ position: 'absolute', paddingRight: '20px', top: '-20px', width: "100%", textAlign: 'right'}}>
            <a href="#" style={{ color: 'white', textDecoration: 'none' }}>{translate('traducciones.login.ayuda')}</a>
            <a href="#" style={{ marginLeft: '30px', color: 'white', textDecoration: 'none' }}>{translate('traducciones.login.cantactenos')}</a>
        </div>
        )
        }
        <span className={classes.spacer} />
        { width != "xs" ? (
        <div>
            <div style={{ width: '100%', textAlign: 'center'}}>
                © {(new Date().getFullYear())} Maximage Data Systems
            </div>
        </div>
        ) : null }
    </AppBar>
    </div>
)};

export default withWidth()(CustomAppBar);