import * as React from 'react';
import { useState} from 'react';
import {
    FormWithRedirect,
    SaveButton,
    useCreate,
    useDelete,
    TextInput,
    useTranslate,
    SimpleForm,    
    Loading, 
    Error, 
    required, 
    Create,
    useRefresh,
    Query,
    useNotify,
    AutocompleteArrayInput,
    useDataProvider
} from 'react-admin';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';

const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    },
    oculto : {
        visibility : "hidden"
    }
});



function dibujaNodosHijos(nodo,classes,addNode,editNode,deleteNode,translate,users,checkPermiso,colapsar,checknodoabierto, reubicarNodo){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => {
                    checknodoabierto(item);
                    return (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px', width: 'max-content'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            { checkPermiso('AgregarNodos') &&
                            <Button title={ translate('traducciones.titulos.addNodosHijos')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 0}}>
                                <AddIcon/>
                            </Button>
                            }
                            { checkPermiso('EditarNodos') &&
                            <Button title={ translate('traducciones.titulos.editNodo')} onClick={() => editNode(item)} style={{ marginLeft: "5px", padding: 0}}>
                                <EditIcon/>
                            </Button>
                            }
                            { checkPermiso('EliminarNodos') &&
                            <Button title={ translate('traducciones.titulos.deleteNodo')} onClick={() => deleteNode(item)} style={{ marginLeft: "5px", padding: 0}}>
                                <DeleteIcon/>
                            </Button>
                            }   
                            { checkPermiso('AsignarAccesoaNodos') &&
                            <Button title={ translate('traducciones.titulos.editarUsuario')} onClick={() => users(item)} style={{ marginLeft: "5px", padding: 0}}>
                                <SupervisorAccountIcon/>
                            </Button>
                            }
                            { checkPermiso('EditarNodos') &&
                            <Button title={ translate('traducciones.titulos.reubicarNodo')} onClick={() => reubicarNodo(item)} style={{ marginLeft: "5px", padding: 0}}>
                                <LocationSearchingIcon/>
                            </Button>
                            }
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode, editNode,deleteNode,translate,users,checkPermiso,colapsar,checknodoabierto,reubicarNodo) }
                        </div>
                        }
                    </div>
                )})
                }
            </div>
        )
    }
}

function dibujaNodosHijosRelocate(nodo,classes,viewNode,translate,nodoActual,colapsar){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoActual == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button className= { nodoActual == item.RutaNodo ? classes.oculto : "" }  title={ translate('traducciones.titulos.reubicar')} onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <LocationSearchingIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijosRelocate(item,classes,viewNode,translate,nodoActual,colapsar) }
                        </div>
                        }
                    </div>
                ))
                }
            </div>
        )
    }
}

const NodosList = ({ ...props }) => {   

    const permisos = props.permissions ? props.permissions.Nodos : null;  
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }

    const classes = useStyles();
    const notify = useNotify();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [loadinga, setLoadinga] = useState(false);
    const [openAdd, setopenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);  
    const [openChangeNode, setOpenChangeNode] = useState(false);
    const [nodoActual, setNodoActual] = useState("");
    
    const [dataUsers, setDataUsers] = useState(null);
    const [dataGrupos, setDataGrupos] = useState(null);

    const [randomUpdate, setRandomUpdate] = useState(null);
    
    const [dataUsersSeleccionados, setDataUsersSeleccionados] = useState(null);
    const [dataGruposSeleccionados, setDataGruposSeleccionados] = useState(null);

    const [create] = useCreate('nodos');
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const refresh = useRefresh();

    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);

    const dataProvider = useDataProvider();

    const handleCloseClick = () => {
        setopenAdd(false);
        setopenDelete(false);
        setOpenEditUser(false);
        setOpenEdit(false);
        setOpenChangeNode(false);
    }

    const handleSubmit = async values => {
        setLoadinga(true);
        create(
            { payload: { data: {NodeName : values.nodoName, IdParentNode : nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/" } } },
            {
                onSuccess: ({ data }) => {
                    setopenAdd(false);
                    setLoadinga(false);
                },
                onFailure: ({ error, message }) => {
                    setLoadinga(false);
                    notify(message, 'error');
                }
            }
        );
    };

    const handleUpdateName = async values => {
        setLoadinga(true);
        values.IdNode = nodoPadreElemento.RutaNodo;
        dataProvider.update('actualizarNombreNodo', { values })
        .then((json) => {
            if(json.data.Success){
                setOpenEdit(false);
                setLoadinga(false);
            }else{
                setLoadinga(false);
            }
        })
        .catch(error => {
        }); 
    };

    const handleSubmitDelete = async values => {
        setLoadinga(true);        
        deleteOne('nodos', nodoPadreElemento.id, nodoPadreElemento,{
            onSuccess: ({ data }) => {
                setopenDelete(false);
                setLoadinga(false);
                refresh();
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
                notify(error, 'error');
            }
        });
        setLoadinga(false);  
        
    };

    const handleSubmitEditUsers = async values => {
        setLoadinga(true);
        dataProvider.update('accessNode', { id: nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", Users : values.usuarios, Grupos : values.grupos })
            .then(({ data }) => {
                setLoadinga(false);
                setOpenEditUser(false);
            })
            .catch(error => {
                setLoadinga(false);
            });   
    };

    const deleteNode = (nodo) => {
        setnodoPadreElemento(nodo);
        setopenDelete(true);
    }

    const viewNode =  (nodo) => {
        setLoadinga(true);
        dataProvider.update('documentoMover', { IdSourceNode: nodoActual, IdDestinationNode: nodo.RutaNodo })
        .then(() => {
            setLoadinga(false);
            setOpenChangeNode(false);
        })
        .catch(error => {
            notify(error, 'error');
            setLoadinga(false);
        }); 
    }

    const editarUsuarios = (nodo) => {
        dataProvider.getList('usuarios', { })
        .then(({ data }) => {
            if(dataUsers == null){
                setDataUsers(data);
            }
            dataProvider.getList('accessNode', { IdNode: nodo != null ? nodo.RutaNodo : "/"})
            .then(({ data }) => {                
                setnodoPadreElemento(nodo);
                var filtrados = [];
                var filtradosg = [];
                if(data != null && data != undefined){
                    data.forEach(function (record) { 
                        if(record.type == "U"){
                            filtrados.push(record.id);
                        }else{
                            filtradosg.push(record.id);
                        }
                    });     
                    setDataUsersSeleccionados(filtrados);
                    setDataGruposSeleccionados(filtradosg);
                }    
                dataProvider.getList('grupos', { })
                .then(({ data }) => {
                    
                    if(dataGrupos == null){
                        setDataGrupos(data);
                    }  
                    setOpenEditUser(true);
                });
            })
            .catch(error => {
            });   
        })
        .catch(error => {
        });
        
        
    }

    const addNode = (nodoPadre) => {
        if(nodoPadre != null){
            setnodoPadreElemento(nodoPadre);
        }else{
            setnodoPadreElemento(null);
        }
        setopenAdd(true);
    }


    const editNode = (nodoPadre) => {
        if(nodoPadre != null){
            setnodoPadreElemento(nodoPadre);
        }else{
            setnodoPadreElemento(null);
        }
        setOpenEdit(true);
    }

    const reubicarNodo = (nodoPadre) => {
        if(nodoPadre != null){
            setNodoActual(nodoPadre.RutaNodo);
        }else{
            setNodoActual(null);
        }
        setOpenChangeNode(true);
    }


    

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }

    if(loadinga){
        return <Loading />
    }

    return (
        <Create {...props} title={ translate('traducciones.titulos.gestionDeNodos')} >
            <SimpleForm {...props} toolbar={ null } className="col-md-custom">
                <div fullWidth>
                { checkPermiso('AgregarNodos') && <Button title={ translate('traducciones.titulos.addNodosPrincipal')} onClick={() => addNode(null)} style={{ marginLeft: "5px", padding: 0}}><AddIcon/></Button> }
                </div>
                <div fullWidth style={{ padding: '10px', overflow: 'auto'}} >                   
                <Query type="getList" resource="nodos" payload={ null }>
                    {({ data, loading, error }) => {
                        if (loading) { return <Loading />; }
                        if (error) { return <Error />; }
                        return (
                            <div>
                            {   data.map(
                                (item) => {
                                    checknodoabierto(item);
                                    return (
                                    <div>
                                        <div className= { classes.nodo } > 
                                            <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                            { item.TextoNodo } 
                                            { checkPermiso('AgregarNodos') &&
                                            <Button title={ translate('traducciones.titulos.addNodosHijos')}  onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 0}}>
                                                <AddIcon/>
                                            </Button>
                                            }
                                            { checkPermiso('EditarNodos') &&
                                            <Button title={ translate('traducciones.titulos.editNodo')} onClick={() => editNode(item)} style={{ marginLeft: "5px", padding: 0}}>
                                                <EditIcon/>
                                            </Button>
                                            }
                                            { checkPermiso('EliminarNodos') &&
                                            <Button title={ translate('traducciones.titulos.deleteNodo')} onClick={() => deleteNode(item)} style={{ marginLeft: "5px", padding: 0}}>
                                                <DeleteIcon/>
                                            </Button>
                                            }
                                            { checkPermiso('AsignarAccesoaNodos') &&
                                            <Button title={ translate('traducciones.titulos.editarUsuario')} onClick={() => editarUsuarios(item)} style={{ marginLeft: "5px", padding: 0}}>
                                                <SupervisorAccountIcon/>
                                            </Button>
                                            }

                                            { checkPermiso('EditarNodos') &&
                                            <Button title={ translate('traducciones.titulos.reubicarNodo')} onClick={() => reubicarNodo(item)} style={{ marginLeft: "5px", padding: 0}}>
                                                <LocationSearchingIcon/>
                                            </Button>
                                            }
                                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                <ExpandLessIcon />
                                            </Button>
                                            }
                                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                <ExpandMoreIcon />
                                            </Button>
                                            }
                                        </div>
                                        { !item.colapsado &&
                                        <div style={{ marginLeft: '-20px'}}>
                                            { dibujaNodosHijos(item,classes,addNode, editNode,deleteNode,translate,editarUsuarios, checkPermiso,colapsar,checknodoabierto, reubicarNodo) }
                                        </div>
                                        }
                                    </div>
                                )})
                            }
                            </div>
                        );
                    }}
                </Query>
                </div>
                <Dialog
                    fullWidth
                    open={openAdd}
                    >
                    <DialogTitle>{ translate('traducciones.titulos.nuevoNodo')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { nodoPadreElemento != null ? (
                            <div style={{ padding: '10px 0', fontWeight: 'bold'}}>{ translate('traducciones.titulos.nodoPadre') }: { nodoPadreElemento.TextoNodo }</div>
                            ) : null }
                            <TextInput style={{ width: '100%'}} source="nodoName" label="traducciones.campos.nodoNombre" validate={required()} />
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.add"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>  
                <Dialog
                    fullWidth
                    open={openDelete}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarIndice')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDelete}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarIndiceConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>   

                <Dialog
                    fullWidth
                    open={openEditUser}
                    >
                    <DialogTitle>{ translate('traducciones.modal.editarUsuarios') + " " + (nodoPadreElemento != null ? nodoPadreElemento.TextoNodo : "") }</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitEditUsers}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            {dataUsers ? (
                            <AutocompleteArrayInput options= { {fullWidth: true } }  optionText="Nombre" defaultValue={ dataUsersSeleccionados } optionValue="Nombre" source="usuarios" choices= { dataUsers ? dataUsers : null } style={{width:'100%'}} />
                            ) : null }
                            {dataUsers ? (
                            <AutocompleteArrayInput options= { {fullWidth: true } }  optionText="GrupoNombre" defaultValue={ dataGruposSeleccionados } optionValue="GrupoNombre" source="grupos" choices= { dataGrupos ? dataGrupos : null } style={{width:'100%'}} />
                            ) : null }
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.save"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>      




                 <Dialog
                    fullWidth
                    open={openEdit}
                    >
                    <DialogTitle>{ translate('traducciones.titulos.cambiarNombreNodo')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleUpdateName}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            <TextInput style={{ width: '100%'}} source="nodoName" label="traducciones.campos.nodoNombre" validate={required()} defaultValue= { nodoPadreElemento ? nodoPadreElemento.TextoNodo : null} />
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.update"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>   


                <Dialog 
                fullWidth
                open={openChangeNode}>
            <DialogTitle>{ translate('traducciones.titulos.reubicarNodo')}</DialogTitle>
            <DialogContent>
                <div style={{ padding: '10px', minWidth: "400px"}}>       
                    <Query type="getList" resource="nodos">
                        {({ data, loading, error }) => {
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-12">
                                    {   data.map(
                                        (item) => (
                                            <div>
                                                <div className= { nodoActual == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button className= { nodoActual == item.RutaNodo ? classes.oculto : "" } title={ translate('traducciones.titulos.reubicar')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <LocationSearchingIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijosRelocate(item,classes,viewNode,translate,nodoActual,colapsar) }
                                                </div>
                                                }
                                            </div>
                                        ))
                                    }
                                    </div>                                        
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseClick} >
                    { translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog> 

            </SimpleForm>


            
        </Create>
)};

export default NodosList;
