import englishMessages from 'ra-language-english';

const customEnglishMessages = {
    ...englishMessages,
    traducciones: {
        visor : {
            anotaciones:'Anotations',
            saveAnotaciones:'Save anotations',
            archivo : 'Documents',
            archivoLocal : 'Open local document',
            archivoUrl : 'Open document from url',
            exportar : 'Export document',
            componer : 'Compose document',
            guardarCache : 'Save document on cache',
            imprimir : 'Print document',
            imprimirPdf : 'Print document in PDF format',
            cerrarEditor : 'Close editor',
            exportarTexto : 'Export text',
            propiedades : 'Properties',
            editar : 'Edit',
            deshacer : 'Undo',
            rehacer : 'Redo',
            borrarAnotacion: 'Delete anotation',
            seleccionarTodo: 'Select All',
            seleccionarTodasAnotaciones: 'Select all anotations',
            limpiarSeleccion: 'Clean selection',
            copiarTexto : 'Copy text',
            buscarTexto: 'Search text',
            ver : 'Show',
            rotarIzquierda: 'Left Rotate',
            rotarDerecha: 'Right Rotate',
            alejar : 'Zoom Out',
            acercar : 'Zoom In',
            tamañoActual : 'Actual size',
            ajustarApagina : 'Fit to page',
            ajustarAancho : 'Fit to width',
            comoSVG: 'Show as SVG',
            comoImagen: 'Show as Picture',
            pagina:'Page',
            primerPagina:'First page',
            paginaAnterior:'Previus page',
            proximaPagina:'Next page',
            ultimaPagina:'Last page',
            rotarPaginaDerecha:'Rotate page to right',
            rotarPaginaIzquierda:'Rotate page to left',
            rotarPaginas:'Rotate pages...',
            simple:'Simple',
            doble:'Double',
            vertical:'Vertical',
            horizontal:'Horizontal',
            interaccion:'Interaction',
            irPaginaPrevia:'Go to previus page',
            irPaginaNext:'Go to next page',
            numPageActual:'Actual page number',
            tamanioReal:'Show real size of the page',
            tamaniEncajar:'Fit image to window',
            tamanioAjustarAncho:'Fit image to window width',
            singlePageDisp:'Single page display',
            doublePageDisp:'Double page display',
            verticalDisplay:'Vertical display',
            horizontalDisplay:'Horizontal display',
            lupa:'Magnifying glass',
            propiedadesAnotacion:'Anotation properties',
            anotacionProps:'Properties',
            anotacionCont:'Content',
            anotacionInfo:'Information',
            cerrar:'Close',
            cargandoDocumento : 'Loading document',
            verificando : 'Verifying server comunication',
            abriendoDocumento : 'Opening document',
        },
        menu: {
            administracion : 'Administration',
            usuarios: 'Users',
            grupos: 'Groups',
            estructura: 'Estructure',
            nodos : 'Nodes',
            indices : 'Document Titles',
            consultas : 'Queries',
            campos: 'By Fields',
            general: 'General',
            contenido: 'By Content',
            digitalizar: 'Digitize',
            desdeArchivo : 'From files',
            indexar: 'Classify',
            navegar : 'Browse'
        },
        titulos:{
            nuevoUsuario : 'New User',
            nuevoGrupo : 'New Group',            
            editarUsuario : 'Edit User',
            editarGrupo : 'Edit Group',
            gestionDeGrupos : 'Group Managment',
            gestionDeUsuarios : 'Users Managment',
            addNodosHijos : 'Add child for this node',
            addNodosPrincipal : 'Add root node',
            deleteNodo : 'Delete this Node',
            nuevoNodo : 'New Node',
            nodoPadre : 'Root Node',
            gestionDeNodos : 'Node managment',
            gestionDeIndices : 'Index mangment',
            nuevoIndice: 'New index',
            busquedaCampos : 'Queries by fields',
            busquedaGeneral : 'General Queries',
            busquedaContenido : 'Queries by content',
            consultarCampos : "Browse this node",
            camposResultados: "Complete the fields to search",            
            resultadoBusqueda : 'Query results',
            archivoDigitalizar : "Files to digitize",
            archivoSeleccionar : "Drag and drop your files here, or click to import them from file",
            imagenesDigitalizar : "Images to digitize",
            imagenesSeleccionar : "Drag and drop your images here, or click to import them from file",
            previsualizacion : "Document preview",
            indexarDocumento : "Index Document",
            digitalizar : "Digitize documents",
            gestionIndexar : 'Management of Documents to Index',
            reasignarDocumento : "Reassign document to another node",
            reasignar : "Reassign to this node",
            reasignarUsuario : "Reassign node to another user",
            consultaNavegacion : "Search by Navigation",
            resultado : "Result",
            porcentaje : "of completeness",
            colapsar : "Collapse child nodes",
            mostrar : "Show child nodes",
            restablecerContraseña : "Restore password",
            cambioContraseña : "Password change / First login",
            plantillaSeleccionada : "Use selected template",
            agregarIndice : "Select index to add",
            ingresando : "Entering..."

        },
        permisos: {
            anotaciones : 'Anotations',
            exportar : 'Export',
            importar : 'Import',
            digitalizar : 'Digitize',
            imprimir : 'Print',
            enviar : 'Send',
        },
        campos: {
            nombre : 'Name',
            usuario : 'User',
            grupo : 'Group',
            nombreDeGrupo : 'Name of group',
            email : 'Email address',
            rol : 'User role',
            estadoPassword: 'Password status',
            estadoUsuario: 'User status',
            vigenciaPassword: 'Password validity (days)',
            nodoNombre : 'Name of Node',
            indice : 'Index',
            tipo : 'Type of index',
            mascara : 'Mask',
            minimo : 'Minimum',
            maximo : 'Maximum',
            longitud : 'Long',
            requerido : 'Required',
            multiple : 'Multiple',
            unico : 'Unique',
            verificar : 'Verify',
            repetido : 'Repeated',
            diccionario : 'Dictionary',
            biometrico : 'Biometric',
            indiceNombre : 'Name of index',
            numeroTemporal : "Nº Temporal Document",
            fechaDigitalizacion : "Fecha de digitalización",
            descripcion : "Description",
            tipoDocumento : "Type of Document",
            passwordOld : "Actual password",
            passwordNew : "New password",
            passwordNewRepeat : "Repeat new password",
            plantillas: 'Select template'

        },
        notificaciones: {
            addUsuarioOk : 'User created successfully',
            editUsuarioOk : 'User saved successfully',
            addGrupoOk : 'Group created successfully',
            editGrupoOk : 'Group saved successfully',
            noHayCampos : 'No fields result for query',
            actualizaAnotacionesOK : 'Anotations saved successfully'
        },
        modal : {
            eliminarGrupo : 'Delete Grup',
            eliminarGrupoConfirm : 'Are you sure you want to delete this group?',
            eliminarUsuario : 'Delete User',
            eliminarUsuarioConfirm : 'Are you sure you want to delete this user?',
            eliminarNodo : 'Delete Nodr',
            eliminarNodoConfirm : 'Are you sure you want to delete this node?',
            eliminarIndice : 'Delete Index', ///////////////
            eliminarIndiceConfirm : 'Are you sure you want to delete this index?', ////////////////////
            editarUsuarios : 'Select user to access the node:',
            noResults : 'Results not found',
            eliminarDocumentoTemporal : 'Delete temp document',
            eliminarDocumentoConfirmTemporal : 'Are you sure you want to delete this temp document?',
            eliminarDocumento : 'Delete Document',
            eliminarDocumentoConfirm : 'Are you sure you want to delete this document?',
        },
        roles : {
            administrador : 'Administrator',
            seguridad : 'Security',
            supervisor : 'Supervisor',
            operador : 'Operator',
            usuario : 'User',
        },
        estadoPassword : {
            caducado : 'Expired',
            vigente : 'Valid' ,
            noCaduca : 'Not Expired' ,
            temporalCaduca : 'Temporary that does expire' ,
            temporalNoCaduca : 'Temporary that does not expire',
        },
        botones : {
            consultar: "Consult",
            subir: "Digitize Documents",
            indiceIndividual: "Add single index"
        },
        error : {
            indiceRepetido: "The index you want to add is already in the list",
            indicePlantillaRepetido : "Some of the aggregate indexes are repeated"
        },
        login : {
            bienvenido : 'Welcome',
            contactenos : 'Contact Us',
            ayuda : 'Help',
            restablecer : 'Restore',
            olvidepass : 'Forgot your password?',
            mensaje : 'We cross borders through secure and reliable solutions and implementations',
            primera : "If you are entering for the first time, click here : "
        }
    }
}

export default customEnglishMessages;