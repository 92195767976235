import * as React from "react";
import { Layout } from 'react-admin';
import CustomAppBar from "./AppBar";
import Menu from "./Menu";

/*
Para poder crear un menu personalizado, React Admin requiere un Custom Layout
este recibira el menu creador por nosotros y el app bar tambien creado por nosotros.

https://marmelab.com/react-admin/doc/3.18/Theming.html#using-a-custom-layout

*/

const CustomLayout = (props) => 

    <Layout {...props} appBar={CustomAppBar}  menu={Menu}></Layout>;

export default CustomLayout;