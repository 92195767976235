import * as React from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import { Button } from "react-admin";

/*
Este es el archivo para crear el componente de visualizacion rápida de documentos.
*/

const DocumentPreview = (props) => {
    //Variable para abrir o no el full screen
    const [full, setFull] = useState(props.clicked != undefined && props.clicked);

    const [modal, setModal] = useState(true);

    //funcion que abre el full screen
    const clickImage = ({}) => {
        setFull(true);
    }
    //funcion que cierra el fullscreen
    const closeImage = ({}) => {
        setFull(false);
        //En caso que al componente se le envie una funcion de callback a la hora de cerrar la imagen la ejecutamos.
        if(props.callback != undefined){
            props.callback();
        }
    }

    const base64ToBlob = ( base64, type = "application/octet-stream" ) => {
        const binStr = atob( base64 );
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          arr[ i ] = binStr.charCodeAt( i );
        }
        return new Blob( [ arr ], { type: type } );
    }

    return (
        <div>
            <div>
            {
                /*
                Evaluamos si el archivo es de tipo Imagen para mostrar el coponente LightBox el cual levanta un visualizador con diferentes opciones
                */
                props.document != null && props.document.Base64file && (props.document.TipoDocumento == "JPEG" || props.document.TipoDocumento == "PNG") ? (  
                    <div>
                    { 
                        full ? (<Lightbox image={"data:image/" + props.document.TipoDocumento +";base64," + props.document.Base64file} title={ "" } onClose={ closeImage} /> )
                        : (
                            <a onClick={clickImage} style={{ cursor: "pointer" }}>
                                <img src={"data:image/" + props.document.TipoDocumento +";base64," + props.document.Base64file} alt="" style={{ maxWidth : "100%", maxHeight: "50vh"}} />
                            </a>
                        )
                    }
                    </div>
                ) : null
            }
            {
                /*
                Si el documento no es un imagen cargamos un embebido del Browser para mostrar el documento. (No funciona con todos los tipos de documentos solo con los basicos)
                */
                props.document != null && props.document.Base64file && props.document.TipoDocumento != "JPEG" && props.document.TipoDocumento != "PNG" ? (  
                    <Dialog open={modal}>
                        <DialogContent>
                            <embed height="400px" width="100%" src= { "data:application/pdf;base64," + props.document.Base64file + "#toolbar=1&navpanes=1&scrollbar=1"} />
                        </DialogContent>
                        <DialogActions>
                        <Button
                            label="ra.action.close"
                            onClick={() => {
                                setModal(false);
                                if(props.callback != undefined){
                                    props.callback();
                                }
                            } }
                        >
                            <IconCancel />
                        </Button>
                    </DialogActions>
                    </Dialog>
                ) : null
            }
            </div>
            <div align="center">            
            {
                props.document != null && props.document.Document && (props.document.DocumentType == "JPEG" || props.document.DocumentType == "PNG")  ? (  
                    <div>
                    { 
                        full ? (<Lightbox image={"data:image/" + props.document.DocumentType +";base64," + props.document.Document} title={ "" } onClose={ closeImage} /> )
                        : (
                            <a onClick={clickImage} style={{ cursor: "pointer" }}>
                                <img src={"data:image/" + props.document.DocumentType +";base64," + props.document.Document} alt="" style={{ maxWidth : "100%", maxHeight: "50vh"}} />
                            </a>
                        )
                    }
                    </div>
                ) : null
            }
            {
                props.document != null && props.document.Document && props.document.DocumentType != "JPEG"  && 
                props.document != null && props.document.Document && props.document.DocumentType != "PNG"  ? (  
                    
                        <embed height="600px" width="100%" src= { URL.createObjectURL(base64ToBlob( props.document.Document, 'application/pdf' )) + "#toolbar=1&navpanes=1&scrollbar=1" } />
                        
                ) : null
            }
            </div>
        </div>
    )

}

export default DocumentPreview;