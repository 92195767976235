import spanishMessages from '@blackbox-vision/ra-language-spanish';

const customSpanishMessages = {
    ...spanishMessages,
    traducciones: {
        visor : {
            anotaciones:'Anotaciones',
            saveAnotaciones:'Guardar anotaciones',
            archivo : 'Documentos',
            archivoLocal : 'Abrir documento local',
            archivoUrl : 'Abrir documento desde URL',
            exportar : 'Exportar documento',
            componer : 'Componer documento',
            guardarCache : 'Guardad documento en cache',
            imprimir : 'Imprimir documento',
            imprimirPdf : 'Imprimir documento en PDF',
            cerrarEditor : 'Cerrar visualizador',
            exportarTexto : 'Exportar texto',
            propiedades : 'Propiedades',
            editar : 'Editar',
            deshacer : 'Deshacer',
            rehacer : 'Rehacer',
            borrarAnotacion: 'Borrar anotación',
            seleccionarTodo: 'Seleccionar todo',
            seleccionarTodasAnotaciones: 'Seleccionar todas las anotaciones',
            limpiarSeleccion: 'Limpiar selección',
            copiarTexto : 'Copiar texto',
            buscarTexto: 'Buscar texto',
            ver : 'Ver',
            rotarIzquierda: 'Rotar a la izquierda',
            rotarDerecha: 'Rotar a la derecha',
            alejar : 'Alejar',
            acercar : 'Acercar',
            tamañoActual : 'Tamaño Actual',
            ajustarApagina : 'Ajustar a la página',
            ajustarAancho : 'Ajustar al ancho',
            comoSVG: 'Ver como SVG',
            comoImagen: 'Ver como Imagen',
            pagina:'Página',
            primerPagina:'Primer página',
            paginaAnterior:'Página anterior',
            proximaPagina:'Próxima página',
            ultimaPagina:'Última página',
            rotarPaginaDerecha:'Rotar página a derecha',
            rotarPaginaIzquierda:'Rotar página a izquierda',
            rotarPaginas:'Rotar páginas...',
            simple:'Simple',
            doble:'Doble',
            vertical:'Vertical',
            horizontal:'Horizontal',
            interaccion:'Interacción',
            irPaginaPrevia:'Ir a página previa en el documento',
            irPaginaNext:'Ir a siguiente página en el documento',
            numPageActual:'Número de página actual',
            tamanioReal:'Mostrar el tamaño real de la página',
            tamaniEncajar:'Encajar la imagen en la ventana',
            tamanioAjustarAncho:'Ajustar el ancho de la imagen en la ventana',
            singlePageDisp:'Visualización de una sola página',
            doublePageDisp:'Visualización doble página',
            verticalDisplay:'Visualización vertical',
            horizontalDisplay:'Visualización horizontal',
            lupa:'Lupa',
            propiedadesAnotacion:'Propiedades de la anotación',
            anotacionProps:'Propiedades',
            anotacionCont:'Contenido',
            anotacionInfo:'Información',
            cerrar:'Cerrar',
            cargandoDocumento : 'Cargando documento',
            verificando : 'Verificando comunicación con el servidor',
            abriendoDocumento : 'Abriendo documento',
        },
        menu: {
            administracion : 'Administración',
            usuarios: 'Usuarios',
            grupos: 'Grupos',
            estructura: 'Estructura',
            nodos : 'Tipos de documentos',
            indices : 'Campos',
            consultas : 'Consultas',
            campos: 'Por Campos',
            general: 'General',
            contenido: 'Por Contenido',
            digitalizar: 'Digitalizar',
            desdeArchivo : 'Desde Archivos',
            indexar: 'Clasificar',
            navegar : 'Navegar',
            escritorio : 'Escritorio',
            bastanteo: 'Por Bastanteo',
            predefinida : "Consultas Predefinidas"
        },
        titulos:{
            nuevoUsuario : 'Nuevo Usuario',
            nuevoGrupo : 'Nuevo Grupo',            
            editarUsuario : 'Editar Usuario',
            editarGrupo : 'Editar Grupo',
            gestionDeGrupos : 'Gestión de Grupos',
            gestionDeUsuarios : 'Gestión de Usuarios',
            addNodosHijos : 'Agregar hijos a este tipo de documento',
            addNodosPrincipal : 'Agregar un tipo de documento principal',
            deleteNodo : 'Eliminar este tipo de documento',
            editNodo : 'Cambiar el nombre de este tipo de documento',
            nuevoNodo : 'Nuevo tipo de documento',
            nodoPadre : 'tipo de documento Padre',
            gestionDeNodos : 'Gestión de tipos de documentos',
            gestionDeIndices : 'Gestión de campos',
            nuevoIndice: 'Nuevo Campo',
            busquedaCampos : 'Consultas por campos',
            busquedaGeneral : 'Consultas general',
            busquedaContenido : 'Consultas por contenido',
            consultarCampos : "Consultar este tipo de documento",
            camposResultados: "Complete los siguientes para consultar",            
            resultadoBusqueda : 'Resultados de la búsqueda',
            archivoDigitalizar : "Archivos para digitalizar",
            archivoSeleccionar : "Arrastre y suelte aquí sus archivos, o haga click para importarlas desde archivo",
            imagenesDigitalizar : "Imágenes para digitalizar",
            imagenesSeleccionar : "Arrastre y suelte aquí sus imágenes, o haga click para importarlas desde archivo",
            previsualizacion : "Previsualización de documentos",
            indexarDocumento : "Indexar Documento",
            digitalizar : "Digitalizar documentos",
            gestionIndexar : 'Gestión de Documentos por Indexar',
            reasignarDocumento : "Reasignar documento a otro tipo de documento",
            reasignar : "Reasignar a este tipo de documento",
            reasignarUsuario : "Reasignar documento a otro usuario",
            consultaNavegacion : "Búsqueda por Navegación",
            resultado : "Resultado",
            porcentaje : "El expediente se ha completado en ",
            colapsar : "Colapsar tipos de documentos hijos",
            mostrar : "Mostrar tipos de documentos hijos",
            restablecerContraseña : "Restablecer Contraseña",
            cambioContraseña : "Cambio de contraseña / Primer ingreso",
            plantillaSeleccionada : "Usar plantilla seleccionada",
            agregarIndice : "Seleccione índice a agregar",
            ingresando : "Ingresando...",
            selectNodo : 'Seleccionar este tipo de documento',
            cambiarNombreNodo : 'Editar nombre del tipo de documento',
            reubicarNodo : 'Reubicar tipo de documento',
            reubicar : 'Reubicar dentro de este tipo de documento',
            busquedaBastanteo : 'Consultas por bastanteo',
            resultadosBastanteo : "Resultados de la busqueda por bastanteo",
            ubicacion : "Ubicación",
            busquedaPredefinida : 'Consultas predefinidas',
            ejecutarConsulta : "Ejecutar Consulta"

        },
        permisos: {
            anotaciones : 'Anotaciones',
            exportar : 'Exportar',
            importar : 'Importar',
            digitalizar : 'Digitalizar',
            imprimir : 'Imprimir',
            enviar : 'Enviar',
            sinmarcaagua : 'Sin marca de agua'
        },
        campos: {
            nombre : 'Nombre',
            usuario : 'Usuario',
            grupo : 'Grupo',
            nombreDeGrupo : 'Nombre del grupo',
            email : 'Correo electrónico',
            rol : 'Rol de usuario',
            estadoPassword: 'Estado de password',
            estadoUsuario: 'Estado de usuario',
            vigenciaPassword: 'Vigencia de password (días)',
            nodoNombre : 'Nombre del tipo de documento',
            indice : 'Titulo del campo',
            tipo : 'Tipo de campo',
            mascara : 'Formato de Campo',
            minimo : 'Mínimo',
            maximo : 'Máximo',
            longitud : 'Longitud',
            requerido : 'Requerido',
            multiple : 'Múltiple',
            unico : 'Único',
            verificar : 'Verificar',
            repetido : 'Repetido',
            diccionario : 'Diccionario',
            biometrico : 'Biométrico',
            indiceNombre : 'Nombre del campo',
            numeroTemporal : "Nº Documento Temporal",
            fechaDigitalizacion : "Fecha de digitalización",
            descripcion : "Descripcion",
            tipoDocumento : "Tipo de Documento",
            passwordOld : "Contraseña actual",
            passwordNew : "Contraseña nueva",
            passwordNewRepeat : "Repetir contraseña nueva",
            plantillas: 'Seleccion plantilla',
            usuarioHabilitado : 'Usuario Activo',
            usuarioDesHabilitado : 'Usuario Bloqueado',
            si : 'SI',
            no : 'NO',
            usuarioDigitalizacion : 'Usuario Digitalizador',
            idQuery : 'Identificador de consulta',
            nombreQuery : 'Nombre de consulta'

        },
        notificaciones: {
            addUsuarioOk : 'Usuario creado correctamente',
            editUsuarioOk : 'Usuario modificado correctamente',
            addGrupoOk : 'Grupo creado correctamente',
            editGrupoOk : 'Grupo modificado correctamente',
            noHayCampos : 'No se encontraron campos para realizar la consulta',
            actualizaAnotacionesOK : 'Las anotaciones fueron guardadas con exito',
            documentosOK : 'La digitalización fue realizada correctamente'
        },
        modal : {
            eliminarGrupo : 'Eliminar Grupo',
            eliminarGrupoConfirm : '¿Está seguro que desea eliminar este grupo?',
            eliminarUsuario : 'Eliminar Usuario',
            eliminarUsuarioConfirm : '¿Está seguro que desea eliminar este usuario?',
            eliminarNodo : 'Eliminar tipo de documento',
            eliminarNodoConfirm : '¿Está seguro que desea eliminar este tipo de documento?',
            eliminarIndice : 'Eliminar Índice', ///////////////
            eliminarIndiceConfirm : '¿Está seguro que desea eliminar este índice?', ////////////////////
            editarUsuarios : 'Seleccione los usuarios con accesso al tipo de documento: ',
            editarUsuariosDocumento : 'Seleccione los usuarios con acceso a este documento',
            noResults : 'No se encontraron resultados',
            eliminarDocumentoTemporal : 'Eliminar Documento Temporal',
            eliminarDocumentoConfirmTemporal : '¿Está seguro que desea eliminar este documento temporal?',
            eliminarDocumento : 'Eliminar Documento',
            eliminarDocumentoConfirm : '¿Está seguro que desea eliminar este documento?',
            ediarDatosDocumento : 'Actualizar datos del documento'
        },
        roles : {
            administrador : 'Administrador',
            seguridad : 'Seguridad',
            supervisor : 'Supervisor',
            operador : 'Operador',
            usuario : 'Usuario',
        },
        estadoPassword : {
            caducado : 'Caducado',
            vigente : 'Vigente' ,
            noCaduca : 'No Caduca' ,
            temporalCaduca : 'Temporal que caduca' ,
            temporalNoCaduca : 'Temporal que no caduca',
        },
        botones : {
            consultar: "Consultar",
            subir: "Digitalizar Documentos",
            indiceIndividual: "Agregar índice Individual",
            descargarSeleccionados : "Descargar Seleccionados",
            descargarCsv : "Descargar datos en CSV"
        },
        error : {
            indiceRepetido: "El índice que desea agregar ya se encuentra en el listado",
            indicePlantillaRepetido : "Alguno de los índices agregados se encuentra repetido",
            noHaySeleccion: "Debe seleccionar al menos un elemento"
        },
        login : {
            bienvenido : 'Bienvenid@',
            contactenos : 'Contáctenos',
            ayuda : 'Ayuda',
            restablecer : 'Restablecer',
            olvidepass : '¿Se te olvidó tu contraseña?',
            mensaje : 'Traspasamos fronteras a través de soluciones e implementaciones seguras y confiables',
            primera : "Si esta ingresando por primera vez, hás click aquí : "
        },
        dash : {
            indexadosXdia : 'Documentos Indexados por día',
            documentos : 'Documentos',
            digitalizados : 'digitalizados'
        },
        generales : {
            gruposTexto1 : 'Los grupos te pueden servir para asignar los mismos permisos a un equipo que tenga las mismas funciones.',
            gruposTexto2 : 'Por ejemplo: Equipo 1 se encarga de solo importar la información.',
            step1 : 'Paso1.-',
            step2 : 'Paso2.-',
            step3 : 'Paso3.-',
            grupostep1 : 'Ponle un Nombre a tu equipo',
            grupostep2 : 'Asigna a los miembros del equipo',
            grupostep3 : 'Selecciona las funciones que tendrá ese equipo.',
            dashstep1 : 'Crea tus estructuras (imagen de carpetas)',
            dashstep2 : 'Crea los campos de búsqueda los cuales son las etiquetas con las cuales vas a identificar a tus documentos dentro de la plataforma',
            dashstep3 : 'Invita a tu Equipo y configura sus permisos',
            dashtitle : 'está lista para que empieces a configurarla'
        }

    }
}

export default customSpanishMessages;