import React from 'react';
import { useState  } from 'react';
import { 
    Create, 
    TextInput, 
    SimpleForm, 
    Toolbar,
    SaveButton,
    SelectInput,
    BooleanInput,
    NumberInput,
    useTranslate,
    ReferenceInput,
    required } from 'react-admin';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';

function arrowGenerator(color) {
    return {
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.95em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent ${color} transparent`,
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.95em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: `${color} transparent transparent transparent`,
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '5px',
        height: '4em',
        top :"45%",
        width: '2em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent ${color} transparent transparent`,
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '1.3em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 0 1em 1em',
          borderColor: `transparent transparent transparent ${color}`,
        },
      },
    };
  }

  const useStyles = makeStyles({
    arrow: {
        position: 'absolute',
        fontSize: 6,
        width: '3em',
        height: '3em',
        '&::before': {
          content: '""',
          margin: 'auto',
          display: 'block',
          width: 0,
          height: 0,
          borderStyle: 'solid',
        },
    },
    htmlPopper: arrowGenerator('#368a48'),
    htmlTooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 300,
      fontSize: "12px",
      border: '3px solid #368a48',
      '& b': {
        fontWeight: '12px',
      },
      boxShadow : '2px 2px 23px #338c46',
      borderRadius: '7px',
      marginTop: '10px'
    },
});

const CreateToolbar = ({ onCancel, ...props }) => {

    const translate = useTranslate();

    
    return (
       <Toolbar {...props}>
           <div>
                <SaveButton label="ra.action.add" {...props} />
                <Button onClick={onCancel} style={{position: 'absolute', right: '26px'}}>{translate('ra.action.cancel')}</Button>
           </div>
       </Toolbar>
)};

const UsuarioCreate = ({ onCancel, onSuccessOk, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [arrowRef, handleArrowRef] = useState(null);
    const [tutorial, setTutorial] = useState(true);
    return (
    <Create {...props} onSuccess={ onSuccessOk }>
        <SimpleForm  toolbar={<CreateToolbar onCancel={onCancel} />}>
            <div style={{ textAlign: 'center', width: '100%'}}>
                <div>{translate('traducciones.titulos.nuevoUsuario')}</div>
                <img src={"adduser.png"} style={{width: '60%'}} />
            </div>
            <Tooltip
                placement="left"
                classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                title={
                    <React.Fragment>
                        Captura el nombre de usuario de cada miembro de tu equipo
                        <span className={classes.arrow} ref={handleArrowRef} />
                    </React.Fragment>
                }>
                <TextInput source="Nombre" label="traducciones.campos.nombre" validate={required()} />
            </Tooltip>
            <Tooltip
                placement="left"
                classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                title={
                    <React.Fragment>
                        Llena el correo electrónico, <br></br>
                        <b>Tip de seguridad:</b> te recomendamos siempre usar los correos institucionales.
                        <span className={classes.arrow} ref={handleArrowRef} />
                    </React.Fragment>
                }>
            <TextInput source="Email" label="traducciones.campos.email" validate={required()} />
            </Tooltip>
            <Tooltip
                placement="left"
                classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                title={
                    <React.Fragment>
                        <Typography color="inherit">Tu eres el Administrador de tu equipo. ¡Felicidades!</Typography>
                        Puedes crear usuarios con perfil de seguridad, operador,  y usuario para agregar seguridad a tu información.Y para que tu equipo tenga solo las herramientas que le van a ayudar a desempeñar mejor su trabajo.
                        Tipos de perfil:<br></br><br></br>
                        <b>Seguridad</b><br></br>
                        - Permite crear usuarios y generar los perfiles de acceso<br></br>
                        - El perfil de seguridad consolida los passwords y tiene acceso a toda la información.<br></br>
                        - Define los permisos de cada usuario operador<br></br><br></br>
                        <b>Operador</b><br></br>
                        - Es el miembro del equipo que tiene acceso a la información de acuerdo a su perfil especifico, pero no puede invitar a nuevos miembros al equipo no reiniciar las contraseñas
                        <br></br><br></br>
                        <b>Usuario administrador</b><br></br>
                        - Permite crear usuarios y generar los perfiles de acceso<br></br>
                        - El perfil de seguridad consolida los passwords y tiene acceso a toda la información.<br></br>
                        - No puede crear mas perfiles de administración, debes contactar a ventas para mayor información.<br></br>
                        <span className={classes.arrow} ref={handleArrowRef} />
                    </React.Fragment>
                }>
            <ReferenceInput label="traducciones.campos.rol"  source="IdTipo" reference="rolesUsuario">
                        <SelectInput  validate={required()}  />
                    </ReferenceInput>  
            </Tooltip>
            <Tooltip
                placement="left"
                classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                title={
                    <React.Fragment>
                        Cuando estas invitando a tu equipo puedes crear un password temporal, que el invitado puede usar una sola vez. Le puedes asignar una caducidad de tiempo con la siguiente función. Esta función es útil para  auditores externos o internos que no deben tener acceso permanente a lo mas valioso que tienes. Tu información.
                        <span className={classes.arrow} ref={handleArrowRef} />
                    </React.Fragment>
                }>
           <ReferenceInput label="traducciones.campos.estadoPassword"  source="EstadoPassword" reference="estadoPassword">
                        <SelectInput  validate={required()}  />
                    </ReferenceInput>
            </Tooltip>
            <NumberInput source="Vigencia" label="traducciones.campos.vigenciaPassword" defaultValue={ 0 } min="0" />
            <Tooltip
                placement="left"
                classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                title={
                    <React.Fragment>
                        Desliza las funciones y permisos que quieres darle a tu equipo. Esto te permite personalizar los accesos a la información tanto, como lo necesites para ajustarse a los perfiles de tu compañía.
                        <br></br>
                        <b>Tip de seguridad:</b> Revisa los perfiles de seguridad con RH o TI para que las funciones se adapten a las políticas de seguridad establecidas por tu empresa.
                        <span className={classes.arrow} ref={handleArrowRef} />
                    </React.Fragment>
                }>
            <div className="row rowNoMessage" style={{ width: '100%'}}>
                <div className="col-md-6">
                    <Tooltip
                    placement="left"
                    classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                    PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                    title={
                        <React.Fragment>
                            La función anotaciones, sirve para generar trazabilidad, en esta función puedes crear marcas de agua, anotaciones que se requieran para algún proceso, o incluso firmar los documentos. Para conexión con APIS de firma digital como DOCUSIGN contacta a ventas.
                            <span className={classes.arrow} ref={handleArrowRef} />
                        </React.Fragment>
                    }>
                        <BooleanInput label="traducciones.permisos.anotaciones" defaultValue={false} source="Permissions.Anotaciones" />
                    </Tooltip>
                </div>
                <div className="col-md-6">
                <Tooltip
                    placement="left"
                    classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                    PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                    title={
                        <React.Fragment>
                            La función Exportar, permite exportar una copia de los documentos de la plataforma, si activaste una marca de agua esta se vera reflejada en la exportacion. Puedes elegir que documentos, y que secciones de los documentos quieres exportar.
                            <b>Tip de seguridad:</b> activa esta función a usuarios externos como consultores, o auditores para que tengan la información que necesitan para realizar su trabajo.
                            <span className={classes.arrow} ref={handleArrowRef} />
                        </React.Fragment>
                    }>
                    <BooleanInput label="traducciones.permisos.exportar" defaultValue={false} source="Permissions.Exportar" />
                    </Tooltip>
                </div>
                <div className="col-md-6">
                <Tooltip
                    placement="left"
                    classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                    PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                    title={
                        <React.Fragment>
                            La función anotaciones, sirve para generar trazabilidad, en esta función puedes crear marcas de agua, anotaciones que se requieran para algún proceso, o incluso firmar los documentos. Para conexión con APIS de firma digital como DOCUSIGN contacta a ventas.
                            <span className={classes.arrow} ref={handleArrowRef} />
                        </React.Fragment>
                    }>
                    <BooleanInput label="traducciones.permisos.importar" defaultValue={false} source="Permissions.Importar" />
                    </Tooltip>
                </div>
                <div className="col-md-6">
                <Tooltip
                    placement="left"
                    classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                    PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                    title={
                        <React.Fragment>
                            La función anotaciones, sirve para generar trazabilidad, en esta función puedes crear marcas de agua, anotaciones que se requieran para algún proceso, o incluso firmar los documentos. Para conexión con APIS de firma digital como DOCUSIGN contacta a ventas.
                            <span className={classes.arrow} ref={handleArrowRef} />
                        </React.Fragment>
                    }>
                    <BooleanInput label="traducciones.permisos.digitalizar" defaultValue={false} source="Permissions.Digitalizar" />
                    </Tooltip>
                </div>
                <div className="col-md-6">
                <Tooltip
                    placement="left"
                    classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                    PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                    title={
                        <React.Fragment>
                            La función anotaciones, sirve para generar trazabilidad, en esta función puedes crear marcas de agua, anotaciones que se requieran para algún proceso, o incluso firmar los documentos. Para conexión con APIS de firma digital como DOCUSIGN contacta a ventas.
                            <span className={classes.arrow} ref={handleArrowRef} />
                        </React.Fragment>
                    }>
                    <BooleanInput label="traducciones.permisos.imprimir" defaultValue={false} source="Permissions.Imprimir" />
                    </Tooltip>
                </div>
                <div className="col-md-6">
                <Tooltip
                    placement="left"
                    classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                    PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                    title={
                        <React.Fragment>
                            La función anotaciones, sirve para generar trazabilidad, en esta función puedes crear marcas de agua, anotaciones que se requieran para algún proceso, o incluso firmar los documentos. Para conexión con APIS de firma digital como DOCUSIGN contacta a ventas.
                            <span className={classes.arrow} ref={handleArrowRef} />
                        </React.Fragment>
                    }>
                    <BooleanInput label="traducciones.permisos.enviar" defaultValue={false} source="Permissions.Enviar" />
                    </Tooltip>
                </div>
                <div className="col-md-6">
                    <BooleanInput label="traducciones.permisos.sinmarcaagua" defaultValue={false} source="Permissions.Sin_marca_de_agua" />
                </div>
            </div>
            </Tooltip>
        </SimpleForm>
    </Create>
)};

export default UsuarioCreate;