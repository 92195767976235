import * as React from 'react';
import { useState } from 'react';
import {
    FormWithRedirect,
    TextInput,
    useDelete,
    useTranslate,
    SimpleForm,
    Loading,
    Create,
    NumberInput,
    SaveButton,
    DateInput,
    useDataProvider,
    Pagination,
    useNotify,
    SelectInput,
    useEditController,
    Edit
} from 'react-admin';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import withWidth from '@material-ui/core/withWidth';
import CardResultado from './Responsive/CardResultado';
import ExternalViewer from '../Libs/ExternalViewer/ExternalViewer';
import TableResultado from '../Components/TableResultado';

/*
Componente dedicado a la busqueda predefinida 
*/

const PredefinidaCampos = ({ width, ...props }) => {


    const { record } = useEditController(props);

    /*
    Props. permisos es un arreglo de permisos que devuelve React Admin, en cada vista (funcion dentro del Auth Provider)
    */
    const permisos = props.permissions ? props.permissions.ConsultadeDocumentos : null;
    const checkPermiso = (permiso) => {
        if (permisos) {
            return permisos[permiso];
        }
        return false;
    }

    const [loadinga, setLoadinga] = useState(false);
    const [openNoResult, setOpenNoResult] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openDocument, setopenDocument] = useState(false);
    const [document, setDocument] = useState(null);
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const [dataSearch, setDataSearch] = useState([]);
    const [metas, setMetas] = useState(null);
    const [visor, setVisor] = useState(null);
    const notify = useNotify();
    const [openDeleteDocumento, setOpenDeleteDocumento] = useState(false);

    const dataProvider = useDataProvider();

    //Paginación///////////////////////////////

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [order, setOrder] = useState("");
    const [sort, setSort] = useState(true);

    const changePage = (nPage) => {
        setPage(nPage);
        reloadPages(nPage, perPage);
    }
    const ChangePerPage = (rows) => {
        setPage(1);
        setPerPage(rows);
        reloadPages(1, rows);
    }

    const cambiaOrden = (column) => {
        setOrder(column);
        reloadPages(null, null, !sort, column);
        setSort(!sort);
    }

    const reloadPages = (npage, nperpage, nsort, norder) => {
        dataProvider.getList('queryPredefinida', {
            idQuery: record.IdQuery,
            PageNumber: npage ? npage : page,
            DocumentsNumber: nperpage ? nperpage : perPage,
            OrderField: norder ? norder : order,
            AscendingOrder: nsort != null ? nsort : sort,
            Metadata: metas,
        })
            .then((data) => {
                if (data != null && data.data.length == 0) {

                    setOpenNoResult(true);
                } else {
                    if (data != null && data.data.length > 0) {
                        setDataSearch(data);
                        setOpenResult(true);
                    }
                }
            })
            .catch(error => {
            });
    }

    ///////////////////////////////////////////

    const handleCloseClick = () => {
        setOpenNoResult(false);
        setOpenResult(false);
    }

    if (loadinga) {
        return <Loading />
    }

    const handleSubmitSearch = async values => {
        var campos = Object.entries(values);
        var meta = [];
        campos.forEach(function (rec, index) {
            if (rec[1] != undefined && rec[1] != null && rec[1] != "" && rec[0].includes("campo-")) {
                meta.push(
                    {
                        "IdDocumento": "",
                        "IdIndice": rec[0].replace('campo-', ''),
                        "ValorLlave": rec[1]
                    }
                );
            }
        });

        setMetas(meta);

        dataProvider.getList('queryPredefinida', {
            idQuery: record.IdQuery,
            PageNumber: 1,
            DocumentsNumber: 10,
            OrderField: "",
            AscendingOrder: true,
            Metadata: meta,
        })
            .then((data) => {
                if (data != null && data.data.length == 0) {

                    setOpenNoResult(true);
                } else {
                    if (data != null && data.data.length > 0) {
                        setDataSearch(data);
                        setOpenResult(true);
                    }
                }
            })
            .catch(error => {
            });
    }

    const consultaDocumento = (elem) => {
        /*
        La funcion consulta documento es la encargada de llamar a la api de maximage para obtener la informacion del documento
        y con esa informacion en base 64, llamar al visor de LeadTools
        */
        //Llamado a la api de documentos
        dataProvider.getOne('documento', { IdDocument: elem.IdDocument })
            .then(({ data }) => {
                data.data.IdDocument = elem.IdDocument;
                setDocument(data.data);
                setOpenResult(false);
                setopenDocument(true);
                /*
                Instancia del visor de LeadTools, en este caso el objeto del aplicativo se llama HTML5Demo, porque asi se llamaba
                en el ejemplo de la documentacion general.
                DocumentViewerDemo, es el objeto del visor en si.
                y la funcion DocumentViewerDemoApp() lo inicializa
                */
                var app = new window.HTML5Demos.DocumentViewerDemo.DocumentViewerDemoApp();

                //Luego de inicializarlo, guardamos ese objeto en react para manipularlo en el componente.
                setVisor(app);

                /*
                Luego de instanciar el objeto del visor enviaremos ciertos parametros hacia el visor
                para que pueda interpretar esta informacion, el objeto que creamos para enviar la informacion 
                lo llamaremos fileExt, haciendo referencia a que es un documento externo al applicativo.
                */

                //Antes de enviar el resultado del servicio al objeto fileExt, adjuntaremos las traducciones correspondientes.
                data.data.traducciones = {
                    'cargandoDocumento': translate('traducciones.visor.cargandoDocumento'),
                    'verificando': translate('traducciones.visor.verificando'),
                    'abriendoDocumento': translate('traducciones.visor.abriendoDocumento'),
                }

                data.data.userName = localStorage.getItem("userName");

                //Luego de cargar los parametros al objeto data.data (que es el objeto resultante del servicio), lo enviaremos al visor mediante el objeto fileExt
                app.fileExt = data.data;

                //Corremos el visor para que interprete los datos, esta interpretacion se puede seguir en el archivo public/Main/Main.js
                app.run();
            })
            .catch(error => {
            });
    }

    /*
    Esta funcion sera la encargada de obtener las anotaciones desde el visor y guardarlas mediante las apis de MAxImage
    */
    const saveAnotations = () => {
        /*
        Ejecutaremos la funcion getAnnotations(), funcion desarrollada para obtener las antoaciones del visor en 
        formato XML, para poder fuardarlas en la api. Esta funcion se encuentra en public/Main/Main.js en la linea 2266
        */
        var anotations = visor.getAnnotations();
        if (anotations.length > 0) {
            dataProvider.update('anotaciones', { DocumentId: document.IdDocument, Annotations: anotations })
                .then(({ data }) => {
                    notify('traducciones.notificaciones.actualizaAnotacionesOK')
                })
                .catch(error => {
                });
        }
    }

    const closeExternal = () => {
        setOpenResult(true);
        setopenDocument(false);
    }


    const deleteDocument = (doc) => {
        setDocument(doc);
        setOpenDeleteDocumento(true);
    }


    const handleSubmitDeleteDocument = async values => {
        deleteOne('documento', document.IdDocument, document, {
            onSuccess: ({ data }) => {
                changePage(page);
                setOpenDeleteDocumento(false);
                setLoadinga(false);
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
            }
        });
    };

    const handleCloseClick2 = () => {
        setOpenDeleteDocumento(false);
    }

    var count = 0;

    return (
        <Edit {...props} title={translate('traducciones.titulos.busquedaPredefinida')} >
            <SimpleForm {...props} toolbar={null}  >
                <div>
                    <h3>{ translate('traducciones.titulos.busquedaPredefinida')} : { record.NombreBusqueda }</h3>
                </div>
                {/* 
                External Viewer es el componente desarrollado para poder respresentar el html del visor de LeadTools en React.
                Este componente contiene el html codificado para react, obtenido desde el archivo Libs/ExternalViewer/index.html
                en este caso enviaremos al componente dos funciones locales:
                closeExternal, que cerrara el visor
                saveAnotations, que guardara las anotaciones cuando el usuario lo indique.
                */}

                {openDocument ? (
                    <ExternalViewer closeExternal={closeExternal} saveAnotations={saveAnotations} />
                ) : (
                    <div fullWidth>
                        <div style={{ padding: '10px' }}>                            
                            <SimpleForm save={handleSubmitSearch} toolbar={<SaveButton label={translate('traducciones.botones.consultar')} icon={<SearchIcon />} />} >
                                <div className="row" fullWidth>
                                    {record.FieldList.map(
                                        (item) => (
                                            <div className="col-md-6">
                                                {item.Tipo == 1 && !item.ValoresTabla ? (<TextInput style={{ width: "100%" }} defaultValue={item.ValorLlave ? item.ValorLlave : ""} type="text" name={"campo-" + item.IdIndice + ""} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} />) : null}

                                                {item.Tipo == 1 && item.ValoresTabla ? (
                                                    <div>
                                                        <SelectInput style={{ width: "100%" }} name={"campo-" + item.IdIndice + ""} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} choices={item.ValoresTabla.map(value => ({ id: value, name: value }))} />
                                                    </div>
                                                ) : null}

                                                {item.Tipo == 2 ? (<NumberInput style={{ width: "100%" }} name={"campo-" + item.IdIndice + ""} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} value="" defaultValue="" />) : null}
                                                {item.Tipo == 3 ? (<TextInput style={{ width: "100%" }} type="text" name={"campo-" + item.IdIndice + ""} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} value="" defaultValue="" />) : null}
                                                {item.Tipo == 4 ? (<DateInput style={{ width: "100%" }} name={"campo-" + item.IdIndice + "]"} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} value="" defaultValue="" />) : null}
                                                {item.Tipo == 5 ? (<TextInput style={{ width: "100%" }} type="time" name={"campo-" + item.IdIndice + ""} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} value="" defaultValue="" />) : null}
                                                {item.Tipo == 6 ? (<TextInput style={{ width: "100%" }} type="email" name={"campo-" + item.IdIndice + ""} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} value="" defaultValue="" />) : null}
                                            </div>
                                        ))
                                    }
                                </div>
                            </SimpleForm>     
                        </div>
                    </div>

                )}


                <Dialog
                    fullWidth
                    open={openNoResult}>
                    <DialogTitle>{translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                    <DialogContent>
                        <WarningIcon style={{ color: '#c18a2d', fontSize: '60px' }} /> {translate('traducciones.modal.noResults')}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseClick} >
                            {translate('ra.action.close')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth
                    open={openDeleteDocumento}
                >
                    <DialogTitle>{translate('traducciones.modal.eliminarDocumento')}</DialogTitle>
                    <FormWithRedirect
                        resource="nodos"
                        save={handleSubmitDeleteDocument}
                        render={({
                            handleSubmitWithRedirect,
                            pristine,
                            saving
                        }) => (
                            <>
                                <DialogContent>
                                    {translate('traducciones.modal.eliminarDocumentoConfirm')}
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        label="ra.action.confirm"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        icon={<DeleteIcon />}
                                    />
                                    <Button onClick={handleCloseClick2} >
                                        {translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    />
                </Dialog>


                <Dialog
                    fullWidth
                    open={openResult}>
                    <DialogTitle>{translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                    <DialogContent>
                        {width != "xs" ? (
                            <TableResultado
                                dataSearch={dataSearch}
                                consultaDocumento={consultaDocumento}
                                deleteDocument={deleteDocument}
                                cambiaOrden={cambiaOrden}
                                order={order}
                                sort={sort}
                                esBastanteo={true}
                                checkPermiso={checkPermiso}
                                perPage={perPage}
                                setPage={changePage}
                                setPerPage={ChangePerPage}
                                page={page}
                            />
                        ) : (
                            <div>
                                {
                                    dataSearch && dataSearch.data && dataSearch.data.map(elem => {
                                        count++;
                                        return (
                                            <CardResultado dataSearch={dataSearch} i={count} cambiaOrden={cambiaOrden} order={order} sort={sort} elem={elem} />
                                        )
                                    })
                                }
                                <Pagination perPage={perPage} setPage={changePage} setPerPage={ChangePerPage} page={page} total={dataSearch.total} />
                            </div>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseClick} >
                            {translate('ra.action.close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            
            </SimpleForm>
        </Edit>
    )
};

export default withWidth()(PredefinidaCampos);
