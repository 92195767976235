import React from 'react';
import { useState } from 'react';
import {
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Card,
    Checkbox
  } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LockIcon from '@material-ui/icons/Lock';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory } from 'react-router-dom';
import jsonExport from 'jsonexport/dist';
import { triggerBase64Download } from 'react-base64-downloader';
import {
    Pagination,
    useTranslate,
    useDataProvider,
    AutocompleteArrayInput,
    FormWithRedirect,
    SaveButton,
    Link,
    TextInput,
    required,
    NumberInput,
    DateInput,
    SelectInput,
    useRefresh, 
    useNotify,
    Loading,
  } from 'react-admin';

/*
Este archivo contiene el componente generico para la visualizacion de los resultado de las busquedas de los documentos.
Anteriormente este codigo se repetia en cada una de las busquedas pero ahora desde este componente se puede utilizar en todas
las busquedas y modificar el listado desde un solo lugar.
Este componente solo se utilizara si los resultados son en un dispositivo con pantalla grande, y no un movil.
*/

const TableResultado = (props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    var dataSearch = props.dataSearch;
    const cambiaOrden = props.cambiaOrden;
    const consultaDocumento = props.consultaDocumento;
    const deleteDocument = props.deleteDocument;
    const order = props.order;
    const sort = props.sort;
    const checkPermiso = props.checkPermiso;
    const perPage= props.perPage;
    const setPage= props.setPage;
    const setPerPage = props.setPerPage;
    const page= props.page;
    const esBastanteo = props.esBastanteo;
    const history = useHistory(); 
    const refresh = useRefresh();
    const notify = useNotify();

    const [dataUsers, setDataUsers] = useState(null);
    const [dataGrupos, setDataGrupos] = useState(null);
    const [openEditUser, setOpenEditUser] = useState(false); 
    const [dataUsersSeleccionados, setDataUsersSeleccionados] = useState(null);
    const [dataGruposSeleccionados, setDataGruposSeleccionados] = useState(null);
    const [document, setDocument] = useState(null);
    const [loadinga, setLoadinga] = useState(false);
    const [openEditDocument, setOpenEditDocument] = useState(false); 
    const [documentoEditar, setDocumentoEditar] = useState(null);
    const [todosSeleccionados, setTodosSeleccionados] = useState(false);


    //Esta sera la funcion para llamar a la api de MaxImage para cambiar los permisos de los socumentos resultantes de la busqueda
    const cambiarPermisosDocumento = (doc) => {
        setDocument(doc);
        dataProvider.getList('usuarios', { })
        .then(({ data }) => {
            if(dataUsers == null){
                setDataUsers(data);
            }
            dataProvider.getOne('permisosDocumentos', { id: doc.IdDocument})
            .then(({ data }) => {  
                if(data != null && data.data != null){
                    if(data.data.Users != null){
                        setDataUsersSeleccionados(data.data.Users);
                    }
                    if(data.data.Groups != null){
                        setDataGruposSeleccionados(data.data.Groups);
                    }
                }
                dataProvider.getList('grupos', { })
                .then(({ data }) => {          
                    if(dataGrupos == null){
                        setDataGrupos(data);
                    }  
                    setOpenEditUser(true);
                });
            })
            .catch(error => {
            });   
        })
        .catch(error => {
        });
    }

    const handleCloseClick = () => {
        setOpenEditUser(false);
    }

    const handleCloseClick2 = () => {
        setOpenEditDocument(false);
    }


    const EditaDocumento = (doc) => {
        setOpenEditDocument(true);
        setDocumentoEditar(doc);
    }

    //Funcion que actualiza los permisos del documento seleccionado en la api de MAxImage
    const handleSubmitEditUsers = async values => {
        setLoadinga(true);
        dataProvider.update('accessDocument', { id: document.IdDocument , Users : values.usuarios, Grupos : values.grupos })
        .then(({ data }) => {
            setLoadinga(false);
            setOpenEditUser(false);
        })
        .catch(error => {
            setLoadinga(false);
        });  
    };


    //Funcion que actualiza los datos del documento seleccionado en la api de MAxImage
    const handleSubmitUpdateDocument = async values => {
        setLoadinga(true);
        setLoadinga(false);
        var datos = [];

        documentoEditar.DatosDocumento.forEach(function (record, index) { 
                record.ValorLlave = values['campo-' + record.IdIndice] ? values['campo-' + record.IdIndice].trim() : record.ValorLlave.trim();
                datos.push(record);
        });

        
        dataProvider.update('documento', { IdDocument: documentoEditar.IdDocument , IdNode : documentoEditar.IdNode, DocumentData : datos })
        .then(({ data }) => {
            setLoadinga(false);
            setOpenEditDocument(false);
        })
        .catch(error => {
            setLoadinga(false);
        });  
    };

    //Funcion para seleccionar un item de la tabla en el caso que tenga el componente de tipo check
    const onToggleItem = (id) => {
        if(dataSearch && dataSearch.data){
            var datatoupdate = dataSearch;
            var all = true;
            datatoupdate.data.forEach(element => {
                if(element.IdDocument == id){
                    if(element.seleccionado != undefined && element.seleccionado){
                        element.seleccionado = false;
                    } else{
                        element.seleccionado = true;
                    }
                }
                
                if(!element.seleccionado){
                    all = false;
                }
                
            });      
            //setdataSearch(datatoupdate);
        }
    }

    //Funcion para seleccionar todos en caso de que exista el selector total
    
    const onToggleAll = () => {
        /*
        if(dataSearch && dataSearch.data){
            var datatoupdate = dataSearch;
            datatoupdate.data.forEach(element => {
                element.seleccionado = !todosSeleccionados;
            });                 
            setTodosSeleccionados(!todosSeleccionados);       
            setdataSearch(datatoupdate);            
            //refresh();
        }
        */
    }


    function b64toBlob(b64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];
    
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
    
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
    
            var byteArray = new Uint8Array(byteNumbers);
    
            byteArrays.push(byteArray);
        }
    
        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    const exportarCsv = () => {
        setLoadinga(true);
        var datatoExport = [];
        if(dataSearch && dataSearch.data){
            var hayseleccionados = false;
            dataSearch.data.forEach(d => {
                let document = []
                if(d.seleccionado){
                    hayseleccionados = true;
                    d.DatosDocumento.forEach(e => {
                        document.push({
                            [e.NombreIndice] : e.ValorLlave
                        });          
                    });
                    datatoExport.push(document);
                }
            });
            if(hayseleccionados){
                jsonExport(datatoExport, function(err, csv){
                    setLoadinga(false);
                    if(err) return console.log(err);
                    const blob = new Blob([csv], { type: "text/plain" });
                    if (window.saveAs) { window.saveAs(blob, 'csv_info.csv'); }
                    else { navigator.saveBlob(blob, 'csv_info.csv'); }           
                });
            }
            if(!hayseleccionados){
                setLoadinga(false);
                notify(translate("traducciones.error.noHaySeleccion"), "error");
            } 
        }else{
            setLoadinga(false);
        }
    }

    const exportar = () => {   
        if(dataSearch && dataSearch.data){
            var datatoupdate = dataSearch;
            var hayseleccionados = false;
            datatoupdate.data.forEach(element => {
                if(element.seleccionado){
                    hayseleccionados = true;
                    setLoadinga(true);
                    dataProvider.getOne('documento', { IdDocument: element.IdDocument})
                    .then(({ data }) => {                        
                        let ext = data.data.TipoDocumento.toLowerCase();
                        if(ext  == "pdf" || ext == "xls" || ext == "xlsx" || ext == "doc" || ext == "docx" || ext == "txt"){
                            if (window.saveAs) { window.saveAs(b64toBlob(data.data.Base64file,"application/" + data.data.TipoDocumento.toLowerCase()), element.IdDocument + '_document'); }
                            else { navigator.saveBlob(b64toBlob(data.data.Base64file,"application/" + data.data.TipoDocumento.toLowerCase()), element.IdDocument + '_document'); }
                        }else{
                            triggerBase64Download("data:image/" + data.data.TipoDocumento.toLowerCase() +";base64," + data.data.Base64file, element.IdDocument + '_document')
                        }
                        setLoadinga(false);
                    })
                }
            });         
            if(!hayseleccionados){
                notify(translate("traducciones.error.noHaySeleccion"), "error");
            } 
        }
    }

    if(loadinga){
        return <Loading />
    }
    
    
    return  (
        <div>
        <Card style={{ overflowX: 'auto'}} >
            <Table className={"customDataGrid"}>
                { false ? (
                <TableHead>

                <TableRow>
                    <TableCell>
                        {/*
                        <Checkbox
                            checked={todosSeleccionados}
                            onClick={() => onToggleAll()}
                        />
                */ }
                    </TableCell>
                    <TableCell>
                    </TableCell>                    
                    <TableCell></TableCell>                    
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    {
                        dataSearch && dataSearch.data && dataSearch.data.length > 0 && dataSearch.data[0].DatosDocumento && dataSearch.data[0].DatosDocumento.map(({ NombreIndice }) => {
                            
                            
                            
                            
                            return  (
                                <TableCell>
                                    <div style={{minWidth: "max-content"}}>
                                        <a className="orderCustom" onClick={() => cambiaOrden(NombreIndice) } >{ NombreIndice}
                                            { NombreIndice == order ? (
                                        <span>
                                        { sort ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}    
                                        </span>                     
                                            ) : null
                                            }                                       
                                        </a>
                                    </div>
                                </TableCell>
                                )
                            }
                        )            
                    }  
                </TableRow>

                </TableHead>
                ) : null }
                <TableBody>
                    {
                        
                        dataSearch && dataSearch.data && dataSearch.data.map(elem => {
                            return (  
                            <TableRow style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
                                <TableCell padding="none" style={{width : '40px', padding: 0}}>
                                    { elem ? (
                                    <Checkbox
                                        checked= { elem.seleccionado }
                                        onClick={() => onToggleItem(elem.IdDocument)}
                                    />
                                    ) : null }
                                </TableCell>
                                <TableCell style={{width : '40px', padding: 0}}>
                                { checkPermiso('AsignarPermisosaDocumento') &&
                                    <Button onClick={ () => EditaDocumento(elem) } >
                                            <EditIcon />
                                    </Button>
                                }
                                </TableCell>
                                    <TableCell style={{width : '40px', padding: 0}}>
                                    { checkPermiso('EliminarDocumentosConsultados') &&
                                        <Button onClick={ () => deleteDocument(elem) } >
                                                <DeleteIcon />
                                        </Button>
                                    }
                                </TableCell>
                                <TableCell style={{width : '40px', padding: 0}}>
                                { checkPermiso('AsignarPermisosaDocumento') &&
                                    <Button onClick={ () => cambiarPermisosDocumento(elem) } >
                                            <LockIcon />
                                    </Button>
                                }
                                </TableCell>
                                <TableCell style={{width : '40px', padding: 0}} title={  translate('traducciones.titulos.ubicacion') + " : " + elem.IdNodePath }>
                                    { consultaDocumento != null ? (
                                        <Button onClick={() => consultaDocumento(elem) } >
                                        <VisibilityIcon />
                                        </Button>
                                        ) : null
                                    }
                                </TableCell>  
                                {
                                    elem && elem.DatosDocumento.map(({ ValorLlave, NombreIndice},index) => {
                                        
                                    return  (                                                    
                                            <TableCell>
                                                { true ? (
                                                <div style={{color:"#5c9d66", minWidth: "max-content"}}>
                                                <b>{NombreIndice}</b>
                                                </div>
                                                ): null }
                                                <div style={{minWidth: "max-content", minHeight: "1.8vh"}}>
                                                { ValorLlave}
                                                </div>
                                            </TableCell>
                                        )
                                    }
                                    
                                    )
                                }
                               
                                
                            </TableRow>
                            )                
                        })
                    }
                </TableBody>
            </Table>
            <div className='row'>
                <div className='col-md-8' style={{
                    minHeight: '52px',
                    paddingLeft: '25px',
                    paddingTop: '10px',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: '400',
                    lineHeight: '1.43',
                    letterSpacing: '0.01071em',
                    fontSize: '14px !important'
                }}>

                    { esBastanteo != null && dataSearch && dataSearch.data && dataSearch.data.length > 3 ? (
                        <Button 
                        style={{ backgroundColor: "#5c9d66", color : "white", padding: "3px 10px", marginRight: '20px'}}
                        component={Link}
                        title = {translate('traducciones.botones.consultar')}
                        onClick={() => {
                            history.push({
                                pathname: '/bastanteoSearch',
                                data: dataSearch.data
                            });
                        } } >
                            {translate('traducciones.botones.consultar')}
                        </Button>
                        ) : null
                    }

                        { translate('traducciones.titulos.porcentaje')}<strong>{dataSearch.porcentaje}%</strong>
                        { checkPermiso('AsignarPermisosaDocumento') &&
                        <div style={{ padding: '10px'}}>
                        <span>
                            <Button 
                            style={{ backgroundColor: "#5c9d66", color : "white", padding: "3px 10px", marginRight: '20px'}}
                             onClick={ () => exportar() } >
                                {translate('traducciones.botones.descargarSeleccionados')}
                            </Button>
                        </span>
                        <span>
                            <Button 
                            style={{ backgroundColor: "#5c9d66", color : "white", padding: "3px 10px", marginRight: '20px'}}
                             onClick={ () => exportarCsv() } >
                                {translate('traducciones.botones.descargarCsv')}
                            </Button>
                        </span>
                        </div>
                        }
                </div>
                <div className='col-md-4'>
                    <Pagination perPage= {perPage} setPage= {setPage} setPerPage = {setPerPage} page={page} total= {dataSearch.total} />
                </div>
            </div>
        </Card>
        <Dialog
            fullWidth
            open={openEditUser}
            >
            <DialogTitle>{ translate('traducciones.modal.editarUsuariosDocumento') }</DialogTitle>
            <FormWithRedirect
            resource="nodos"
            save={handleSubmitEditUsers}
            render={({
                handleSubmitWithRedirect,
                pristine,
                saving
            }) => (
                <>
                <DialogContent>
                    {dataUsers ? (
                    <AutocompleteArrayInput options= { {fullWidth: true } }  optionText="Nombre" defaultValue={ dataUsersSeleccionados } optionValue="Nombre" source="usuarios" choices= { dataUsers ? dataUsers : null } style={{width:'100%'}} />
                    ) : null }
                    {dataUsers ? (
                    <AutocompleteArrayInput options= { {fullWidth: true } }  optionText="GrupoNombre" defaultValue={ dataGruposSeleccionados } optionValue="GrupoNombre" source="grupos" choices= { dataGrupos ? dataGrupos : null } style={{width:'100%'}} />
                    ) : null }
                </DialogContent>
                <DialogActions>
                    <SaveButton
                        label="ra.action.save"
                        handleSubmitWithRedirect={
                            handleSubmitWithRedirect
                        }
                        pristine={pristine}
                        saving={saving}
                    />
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.cancel')}
                    </Button>
                </DialogActions>
                </>
            )}
        />
        </Dialog>



        <Dialog
            fullWidth
            open={openEditDocument}
            >
            <DialogTitle>{ translate('traducciones.modal.ediarDatosDocumento') }</DialogTitle>
            <FormWithRedirect
            resource="nodos"
            save={handleSubmitUpdateDocument}
            render={({
                handleSubmitWithRedirect,
                pristine,
                saving
            }) => (
                <>
                <DialogContent>
                <div fullWidth>
                    <div className="row">
                        <div className="col-md-12">
                            { documentoEditar ? (
                                <div className="row">
                                    {   
                                    documentoEditar.DatosDocumento.map(
                                        (item) => {
                                            return (
                                            <div className="col-md-6">
                                                
                                                { item.TipoIndice == 1 && !item.ValoresTabla ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice  }  />) : null }

                                                { item.TipoIndice == 1 && item.ValoresTabla ? (
                                                    <div>
                                                        <SelectInput style={{ width: "100%" }} name={ "campo-" + item.IdIndice + "" } defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } />
                                                    </div>
                                                ) : null }
                                                
                                                { item.TipoIndice == 2 ? (<NumberInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.TipoIndice == 3 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.TipoIndice == 4 ? (<DateInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "]" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.TipoIndice == 5 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.TipoIndice == 6 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                            </div>
                                        )})
                                    }    
                                </div>
                                ) : null 
                            }
                        </div>
                    </div>
                    
                </div>
                </DialogContent>
                <DialogActions>
                    <SaveButton
                        label="ra.action.save"
                        handleSubmitWithRedirect={
                            handleSubmitWithRedirect
                        }
                        pristine={pristine}
                        saving={saving}
                    />
                    <Button onClick={handleCloseClick2} >
                        { translate('ra.action.cancel')}
                    </Button>
                </DialogActions>
                </>
            )}
        />
        </Dialog>
        </div>
    );
}

export default TableResultado;