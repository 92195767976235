import * as React from 'react';
import { useState ,useEffect } from 'react';
import {
    FormWithRedirect,
    useCreate,
    useDelete,
    useTranslate,
    SimpleForm,  
    Loading, 
    Error, 
    FileInput, 
    Create,
    useRefresh,
    Query, 
    FileField ,
    ImageField,
    SaveButton,
    ImageInput,
    useDataProvider,
    useNotify,
    ReferenceInput,
    AutocompleteArrayInput,
    TextInput 
} from 'react-admin';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import WarningIcon from '@material-ui/icons/Warning';
import ImageIcon from '@material-ui/icons/Image';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PublishIcon from '@material-ui/icons/Publish';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Card,
  } from '@material-ui/core';

const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        width : 'max-content'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    }
});

function dibujaNodosHijos(nodo,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => {
                    checknodoabierto(item);
                    return (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button title={ translate('traducciones.titulos.consultarCampos')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <ExitToAppIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                        </div>
                        }
                    </div>
                )})
                }
            </div>
        )
    }
}

const BusquedaCampos = ({ ...props }) => {   

    const classes = useStyles();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [loadinga, setLoadinga] = useState(false);
    const [openNoResult, setOpenNoResult] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [openDocument, setopenDocument] = useState(false);    
    const [indiceSeleccionado, setindiceSeleccionado] = useState(null);
    const [create] = useCreate('indices');
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const refresh = useRefresh();
    const [dataIndices, setDataIndices] = useState([]);
    const [dataSearch, setDataSearch] = useState([]);
    const [dataUsers, setDataUsers] = useState(null);
    const [usuarioLocal, setUsuarioLocal] = useState(null);
    const notify = useNotify();

    const [randomUpdate, setRandomUpdate] = useState(null);
    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);

    const dataProvider = useDataProvider();

    useEffect(() => {
        if(dataUsers == null){
            setDataUsers([]);
            dataProvider.getList('usuarios', { })
            .then(({ data }) => {
                var datareturn = [];
                data.forEach(function (record) { 
                    var rd = {};
                    rd.id = record.IdUsuario;
                    rd.Nombre = record.Nombre;
                    datareturn.push(rd);
                });  
                setDataUsers(datareturn);
                var arr = [];
                arr.push(parseInt(localStorage.getItem("IdUsuario")));
                setUsuarioLocal(arr);
            });
        }
    });

    if(dataUsers == null){
        return <Loading />;
    }

    const viewNode = (nodoPadre) => {
        setnodoPadreElemento(nodoPadre);   
    }

    const handleCloseClick = () => {
        setOpenNoResult(false);
        setOpenResult(false);
        setopenDelete(false);
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }

    if(loadinga){
        return <Loading />
    }

    const handleSubmitDelete = async values => {
        setLoadinga(true);        
        deleteOne('indices', indiceSeleccionado.id, indiceSeleccionado,{
            onSuccess: ({ data }) => {
                setDataIndices(data.data);
                setopenDelete(false);
                setLoadinga(false);
                refresh();
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
            }
        });
        setLoadinga(false);  
        
    };

    const convertFileToBase64 = file => 
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

    const handleSubmitSearch = async values => {

        if((values.imagenes == undefined || values.imagenes.length == 0) && (values.archivos == undefined || values.archivos.length == 0)){
            notify("Seleccione al menos un archivo o imagen", 'error');
            return Promise.resolve({ id:0, data: []});
        }
        var newPictures;
        if(values.imagenes){
            newPictures = values.imagenes.filter(
                p => p.rawFile instanceof File
            );
        }
        if(values.archivos){
            if(newPictures){
                newPictures.push.apply(newPictures,values.archivos.filter(
                    p => p.rawFile instanceof File
                ));
            }else{
                newPictures = values.archivos.filter(
                    p => p.rawFile instanceof File
                );
            }
        }

        return Promise.all(newPictures.map(convertFileToBase64))
        .then(base64Pictures => {
                var archivos = [];
                base64Pictures.map(picture64 => {

                    var extension = picture64.split(';')[0].split('/')[1];
                    if(extension.includes("officedocument.wordprocessingml")){
                        extension =  "docx";    
                    }
                    if(extension.includes("officedocument.spreadsheet")){
                        extension =  "xlsx";    
                    }

                    archivos.push(
                    {
                        IdNode : nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/",
                        OperationType : "DIGITALIZACION",
                        AssignedUserId : values.user? values.user[0] : null,
                        DocumentPages : 6,
                        Description : values.descripcion,
                        DocumentType : extension,
                        IdPlantilla : 0,
                        Document : picture64.split("base64,")[1]
                    })  
                });
                
                dataProvider.create('documentos', { data: archivos })
                .then(({ data }) => {
                    refresh();
                    notify('traducciones.notificaciones.actualizaAnotacionesOK')
                })
                .catch(error => {
                    notify(error, 'error');
                });   

            }
        );

    }

    const consultaDocumento = (elem) => {

        dataProvider.getOne('documento', { IdDocumento: elem.IdDocumento})
            .then(({ data }) => {
            })
            .catch(error => {
        });
    } 

    

    return (
        <Create {...props} title={ translate('traducciones.titulos.digitalizar')} >
            <SimpleForm {...props} toolbar={ null }  >
            <div fullWidth>
                
                <div style={{ padding: '10px'}}>       
                    <Query type="getList" resource="nodos" payload={ nodoPadreElemento }>
                        {({ data, loading, error }) => {
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-5 col-md-custom" style={{ overflowX: "auto", padding: "10px"}}>
                                    {   data.map(
                                        (item) => {
                                            checknodoabierto(item);
                                            return (
                                            <div>
                                                <div className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button title={ translate('traducciones.titulos.consultarCampos')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <ExitToAppIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                                                </div>
                                                }
                                            </div>
                                        )})
                                    }
                                    </div>
                                    {
                                        nodoPadreElemento != null ? (
                                    <div  className="col-md-7 col-md-custom">
                                        <div>
                                            <SimpleForm save={ handleSubmitSearch } toolbar= { <SaveButton label={translate('traducciones.botones.subir')} icon= { <PublishIcon /> } /> } >
                                                <div className="row">
                                                <ImageInput source="imagenes" multiple={true} label={translate('traducciones.titulos.imagenesDigitalizar')} accept="image/*,application/pdf" placeholder={
                                                    <div>
                                                        <div><ImageIcon style={{ fontSize: "50px", color:"#319b426e"}} /></div>
                                                        <p>{translate('traducciones.titulos.imagenesSeleccionar')}</p>
                                                    </div>
                                                }>
                                                        <ImageField source="src" title="title" />
                                                </ImageInput>
                                                <FileInput source="archivos" multiple={true} label={translate('traducciones.titulos.archivoDigitalizar')} accept="application/*" placeholder={
                                                    <div>
                                                        <div><FileCopyIcon style={{ fontSize: "50px", color:"#319b426e"}} /></div>
                                                        <p>{translate('traducciones.titulos.archivoSeleccionar')}</p>
                                                    </div>
                                                    }>
                                                    <FileField source="src" title="title" />
                                                </FileInput>
                                                <TextInput multiline={true} rows="4"  style={{ width: "100%" }} type="text" name={ "descripcion" } resettable={ true } label={ "Descripción"} source="descripcion" value="" defaultValue="" />
                                                <div>Usuario al que se le asignaran estos documentos</div>
                                                { dataUsers != null ? (
                                                <AutocompleteArrayInput defaultValue = { usuarioLocal } label="traducciones.campos.usuario"  choices= { dataUsers ? dataUsers : null }  optionText="Nombre" optionValue="id" source="user" /> 
                                                ) : null }
                                                </div>   
                                            </SimpleForm>  
                                        </div>    
                                    </div>
                                    ) : null }
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </div>     
            <Dialog
                open={openNoResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.modal.noResults')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>   


            <Dialog
                open={openResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <Card >
                        <Table className={"customDataGrid"}>
                            <TableHead>
                            <TableRow>
                                <TableCell>

                                </TableCell>
                                {
                                dataSearch && dataSearch.length > 0 && dataSearch[0].DatosDocumento && dataSearch[0].DatosDocumento.map(({ NombreIndice }) => {
                                    return  (
                                            <TableCell>
                                                { NombreIndice}
                                            </TableCell>
                                        )
                                    }
                                )            
                                }
                                <TableCell>

                                </TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                   dataSearch && dataSearch.map(elem => {
                                        return (  
                                        <TableRow>
                                            <TableCell>
                                                <Button onClick={() => consultaDocumento(elem) } >
                                                <VisibilityIcon />
                                                </Button>
                                            </TableCell>  
                                            {
                                                elem && elem.DatosDocumento.map(({ ValorLlave},index) => {
                                                    
                                                return  (                                                    
                                                        <TableCell>
                                                            { ValorLlave}
                                                        </TableCell>
                                                    )
                                                }
                                                
                                                )
                                            }
                                             <TableCell>
                                                <DeleteIcon />
                                            </TableCell>
                                        </TableRow>
                                        )                
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>     

            <Dialog
                open={openDocument}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.modal.noResults')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>      


            <Dialog
                    open={openDelete}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarNodo')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDelete}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarNodoConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>         

            </SimpleForm>
        </Create>
)};

export default BusquedaCampos;
