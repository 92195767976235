import React, {useEffect} from 'react';
import './App.css';
import { Admin, Resource, Logout,resolveBrowserLocale } from 'react-admin';
import { provider } from './DataProviders/GlobalDataProvider';
import { AuthProvider } from './DataProviders/AuthProvider';
import { LoginPage } from './Views/Login'
import CustomLayout from './Components/CustomLayout';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import UsuariosList from './Views/UsuariosList';
import GruposList from './Views/GruposList';
import IndicesList from './Views/IndicesList'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import 'bootstrap/dist/css/bootstrap.min.css';
import NodosList from './Views/NodosList';
import customTranslationMessagesES from './i18n/es'
import customTranslationMessagesEN from './i18n/en'
import customTranslationMessagesPT from './i18n/pt'
import customTranslationMessagesFR from './i18n/fr'
import BusquedaCampos from './Views/BusquedaCampos';
import BusquedaGeneral from './Views/BusquedaGeneral';
import BusquedaContenido from './Views/BusquedaContenido';
import DigitalizarImagenes from './Views/DigitalizarImagenes';
import TempDocumentList from './Views/TempDocumentList';
import DocumentIndexar from './Views/TempDocumentIndexar'
import BusquedaNavegar from './Views/BusquedaNavegar';
import DashBoard from './Views/DashBoard';
import DocumentAnalizer from './Components/DocumentAnalizer';
import BusquedaBastanteo from './Views/BusquedaBastanteo';
import { BrowserRouter } from 'react-router-dom';
import BusquedaPredefinida from './Views/BusquedaPredefinida';
import PredefinidaCampos from './Views/PredefinidaCampos';

const messages = {
    'es': customTranslationMessagesES,
    'en': customTranslationMessagesEN,
    'fr': customTranslationMessagesFR,
    'pt': customTranslationMessagesPT,
};

const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.en,
    resolveBrowserLocale(),
    {
      allowMissing: true
    }
);

const App = () => {

    const LogoutButton = props => <Logout {...props} icon={<ExitToAppIcon/>} />;    
    return (
        <BrowserRouter basename='./'>
        <Admin 
            locale="es" 
            i18nProvider={i18nProvider}
            layout={ CustomLayout }
            dataProvider={provider}
            authProvider={AuthProvider}
            logoutButton={LogoutButton}
            loginPage={ LoginPage }
            >
              <Resource name="dashboard" list={DashBoard} />
              <Resource name="general" list={BusquedaGeneral} />
              <Resource name="bastanteo" list={BusquedaBastanteo} />
              <Resource name="usuarios" list={UsuariosList} />
              <Resource name="grupos" list={GruposList}  />
              <Resource name="nodos" list={NodosList} />
              <Resource name="indices" list={IndicesList} />
              <Resource name="campos" list={BusquedaCampos} />
              <Resource name="contenido" list={BusquedaContenido} />
              <Resource name="navegar" list={BusquedaNavegar} />
              <Resource name="digitalizarImagenes" list={DigitalizarImagenes} />
              <Resource name="documentos" list={TempDocumentList} edit= {DocumentIndexar} />  
              <Resource name="plantillas" />
              <Resource name="estadoPassword" />
              <Resource name="rolesUsuario" />
              <Resource name="bastanteoSearch" list={DocumentAnalizer} />
              <Resource name="predefinida" list={BusquedaPredefinida} edit={PredefinidaCampos} />
        </Admin>
    </BrowserRouter>
)};

export default App;