import React from 'react';
import { 
    Create, 
    TextInput, 
    SimpleForm, 
    Toolbar,
    SaveButton,
    BooleanInput,
    AutocompleteArrayInput,
    useTranslate,
    required } from 'react-admin';
import Button from '@material-ui/core/Button';
import { ReferenceInput } from 'react-admin';

const CreateToolbar = ({ onCancel, ...props }) => {
    
    const translate = useTranslate();

    return(
       <Toolbar {...props}>
           <div>
                <SaveButton label="ra.action.add" {...props} />
                <Button onClick={onCancel} style={{position: 'absolute', right: '26px'}}>{translate('ra.action.cancel')}</Button>
           </div>
       </Toolbar>
)};

const GrupoCreate = ({ onCancel, onSuccessOk, ...props }) => {
    
    const translate = useTranslate();

    return (
    <Create {...props} onSuccess={ onSuccessOk }>
        <SimpleForm  toolbar={<CreateToolbar onCancel={onCancel} />}>
            <div style={{ textAlign: 'center', width: '100%'}}>
                <div>{translate('traducciones.titulos.nuevoGrupo')}</div>
                <img src={"grupo.png"} style={{width: '60%'}} />
            </div>
            <TextInput source="GrupoNombre" label="traducciones.campos.nombreDeGrupo" validate={required()} />  
            <ReferenceInput label="traducciones.menu.usuarios" source="Users" reference="usuarios">
                <AutocompleteArrayInput  optionText="Nombre" optionValue="Nombre" />
            </ReferenceInput>          
            <div className="row rowNoMessage" style={{ width: '100%'}}>
                <div className="col-md-6">
                    <BooleanInput label="traducciones.permisos.anotaciones" defaultValue={false} source="Permissions.Anotaciones" />
                </div>
                <div className="col-md-6">
                    <BooleanInput label="traducciones.permisos.exportar" defaultValue={false} source="Permissions.Exportar" />
                </div>
                <div className="col-md-6">
                    <BooleanInput label="traducciones.permisos.importar" defaultValue={false} source="Permissions.Importar" />
                </div>
                <div className="col-md-6">
                    <BooleanInput label="traducciones.permisos.digitalizar" defaultValue={false} source="Permissions.Digitalizar" />
                </div>
                <div className="col-md-6">
                    <BooleanInput label="traducciones.permisos.imprimir" defaultValue={false} source="Permissions.Imprimir" />
                </div>
                <div className="col-md-6">
                    <BooleanInput label="traducciones.permisos.enviar" defaultValue={false} source="Permissions.Enviar" />
                </div>
                <div className="col-md-6">
                    <BooleanInput label="traducciones.permisos.sinmarcaagua" defaultValue={false} source="Permissions.Sin_marca_de_agua" />
                </div>
            </div>
        </SimpleForm>
    </Create>
)};

export default GrupoCreate;