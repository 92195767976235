import React from 'react';
import { useState  } from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    useTranslate,
    Pagination,
    DeleteButton,
    Loading, 
    Error, 
    useRefresh,
    Query, 
    Link,
    useDataProvider,
    ReferenceInput,
    AutocompleteInput,
    SimpleForm,
    SaveButton
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import withWidth from '@material-ui/core/withWidth';
import MobileTempDocumentList from './Responsive/MobileTempDocumentList';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        width : 'max-content'
    },
    nodoSeccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    },
    oculto : {
        visibility : "hidden"
    }
});

function dibujaNodosHijos(nodo,classes,viewNode,translate,nodoActual,colapsar){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoActual == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button className= { nodoActual == item.RutaNodo ? classes.oculto : "" }  title={ translate('traducciones.titulos.reasignar')} onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <LocationSearchingIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,viewNode,translate,nodoActual,colapsar) }
                        </div>
                        }
                    </div>
                ))
                }
            </div>
        )
    }
}



const TempDocumentList = ({ width,...props }) => {

    const permisos = props.permissions ? props.permissions.IndexacióndeDocumentos : null;    
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }

    const translate = useTranslate();
    const classes = useStyles();  
    const [openResult, setOpenResult] = useState(false);
    const [openModalUser, setOpenModalUser] = useState(false);    
    const refresh = useRefresh();
    const [dataDocument, setDataDocument] = useState(null);
    const [nodoActual, setNodoActual] = useState("");
    const dataProvider = useDataProvider();

    const [randomUpdate, setRandomUpdate] = useState(null);

    const handleCloseClick = () => {
        setOpenResult(false);
        setOpenModalUser(false);
    }    
    
    const viewNode = (nodoPadre) => {
        dataProvider.update('documentoTemporal', { id: dataDocument ? dataDocument.IdDocumentTmp : 0, IdNode: nodoPadre.RutaNodo })
        .then(() => {
            setOpenResult(false);
            refresh();
        })
        .catch(error => {
        });   
    }

    const selectNode = (record) => {
        setDataDocument(record);
        setNodoActual(record.IdNode);
        setOpenResult(true);
    }

    const selectUser = (record) => {
        setDataDocument(record);
        setOpenModalUser(true);
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        nodo.colapsado = !nodo.colapsado;
    }

    const handleAsignarUsuario = async values => {
        dataProvider.update('documentoTemporalUser', { idUser: values.user, IdDocumentTmp: dataDocument ? dataDocument.IdDocumentTmp : 0 })
        .then(() => {
            setOpenModalUser(false);
            refresh();
        })
        .catch(error => {
        }); 
    }

    const ListPagination = props => <Pagination rowsPerPageOptions={[5,10, 25, 50, 100]} {...props} />;

    const ChangeNodeButton = ({ record, ...props }) => (
        <Button
            style={{ backgroundColor: "#5c9d66", color : "white", padding: "3px 4px"}}
            component={Link}
            title = { props.title }
            onClick={ () => { selectNode(record) } }>
                <CallSplitIcon /> 
        </Button>
    );

    const ChangeUserButton = ({ record,...props  }) => (
        <Button
            style={{ backgroundColor: "#5c9d66", color : "white", padding: "3px 4px"}}
            component={Link}
            title = { props.title }
            onClick={ () => { selectUser(record) } }>
                <SupervisorAccountIcon /> 
        </Button>
    );

    return (
    <div>    
        <List
            {...props}
            title={translate('traducciones.titulos.gestionIndexar')}
            sort={{ field: 'IdDocumentTmp', order: 'ASC' }}
            registro={ props.record} 
            bulkActionButtons={false}            
            actions = {null}
            pagination={<ListPagination />} >
                { width != "xs" ? (  
                <Datagrid isRowSelectable={record => record.id < 1} >
                    <TextField source="IdDocumentTmp" label= "traducciones.campos.numeroTemporal" />
                    <TextField source="AssignedUser" label= "traducciones.campos.usuarioDigitalizacion" />
                    <TextField source="DateTime" label= "traducciones.campos.fechaDigitalizacion" />
                    <TextField source="Description" label= "traducciones.campos.descripcion" />
                    <TextField source="DocumentType" label= "traducciones.campos.tipoDocumento" sortable = { false } />
                    { checkPermiso('Indexar') &&<EditButton label="" className="actionButton" title= { translate("traducciones.titulos.indexarDocumento") } /> }
                    { checkPermiso('Indexar') &&<ChangeNodeButton title= { translate("traducciones.titulos.reasignarDocumento") } />     }
                    { checkPermiso('Indexar') &&<ChangeUserButton title= { translate("traducciones.titulos.reasignarUsuario") } /> }
                    { checkPermiso('EliminarDocumentosPendientesdeIndexar') &&<DeleteButton title={translate('traducciones.modal.eliminarDocumento')} label="" className="actionButton" undoable={false} confirmTitle={translate('traducciones.modal.eliminarDocumento')} confirmContent={translate('traducciones.modal.eliminarDocumentoConfirm')}></DeleteButton> }
                </Datagrid>
                ) : (
                    <MobileTempDocumentList selectNode = {selectNode} selectUser = { selectUser } checkPermiso = { checkPermiso } />
                )
            }
        </List>

        <Dialog open={openResult}>
            <DialogTitle>{ translate('traducciones.titulos.reasignarDocumento')}</DialogTitle>
            <DialogContent>
                <div style={{ padding: '10px', minWidth: "400px"}}>       
                    <Query type="getList" resource="nodos">
                        {({ data, loading, error }) => {
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-12">
                                    {   data.map(
                                        (item) => (
                                            <div>
                                                <div className= { nodoActual == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button className= { nodoActual == item.RutaNodo ? classes.oculto : "" } title={ translate('traducciones.titulos.reasignar')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <LocationSearchingIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoActual,colapsar) }
                                                </div>
                                                }
                                            </div>
                                        ))
                                    }
                                    </div>                                        
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseClick} >
                    { translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog> 

        <Dialog open={openModalUser}>
            <DialogTitle>{ translate('traducciones.titulos.reasignarUsuario')}</DialogTitle>
            <DialogContent>
                <SimpleForm save={handleAsignarUsuario} toolbar = { <SaveButton label ="Re Asignar" />}>
                    <ReferenceInput label="traducciones.menu.usuarios" source="user" reference="usuarios">
                        <AutocompleteInput optionText="Nombre" optionValue="IdUsuario" />
                    </ReferenceInput> 
                </SimpleForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseClick} >
                    { translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog>  

        
    </div> 
)};

export default withWidth()(TempDocumentList);
