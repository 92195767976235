import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    TopToolbar,
    CreateButton,
    DeleteButton,
    EditButton,
    useRefresh,
    useRedirect,
    useNotify,
    useTranslate,
    Pagination
} from 'react-admin';
import UsuarioCreate from './UsuarioCreate';
import UsuarioEdit from './UsuarioEdit';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import MobileUsuarioList from './Responsive/MobileUsuarioList';


const UsuariosActions = ({ 
    basePath,
    checkPermiso
    }) => {   
    return (
    <TopToolbar>
        { checkPermiso('AgregarUsuarios') && <CreateButton basePath={basePath} label="traducciones.titulos.nuevoUsuario"  /> }
    </TopToolbar>
)};

const UsuariosList = ({ width,...props }) => {

    const permisos = props.permissions ? props.permissions.Usuarios : null;    
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }

    const refresh = useRefresh();
    const redirect = useRedirect();
    const notify = useNotify();

    const ListPagination = props => <Pagination rowsPerPageOptions={[5,10, 25, 50, 100]} {...props} />;

    const translate = useTranslate();

    const handleClose = () => {
        props.history.push("/usuarios");
    };

    const onSuccess = () => {
        redirect('/usuarios');
        notify('traducciones.notificaciones.addUsuarioOk')
        refresh();
    };

    const onSuccessEdit = () => {
        redirect('/usuarios');
        notify('traducciones.notificaciones.editUsuarioOk')
        refresh();
    };

    return (
    <div>    
        <List
            {...props}
            title={translate('traducciones.titulos.gestionDeUsuarios')}
            actions = { <UsuariosActions checkPermiso = { checkPermiso } /> }
            sort={{ field: 'Nombre', order: 'ASC' }}
            registro={ props.record} 
            bulkActionButtons={false}
            pagination={<ListPagination />}
            >
            { width !== "xs" ? (    
        <Datagrid isRowSelectable={record => record.id < 1} >
                <TextField source="Nombre" label= "traducciones.campos.nombre" />
                <TextField source="Rol" label= "traducciones.campos.rol" />
                <TextField source="NombreEstadoUsuario" label= "traducciones.campos.estadoUsuario" />
                <TextField source="NombreEstadoPassword" label= "traducciones.campos.estadoPassword" />
                <TextField source="Vigencia" label= "traducciones.campos.vigenciaPassword" />
                <TextField source="Email" label= "traducciones.campos.email" />
                { checkPermiso('EditarUsuarios') && <EditButton label="" className="actionButton" /> }
                { checkPermiso('EliminarUsuarios') && <DeleteButton label="" className="actionButton" undoable={false} confirmTitle={translate('traducciones.modal.eliminarUsuario')} confirmContent={translate('traducciones.modal.eliminarUsuarioConfirm')}></DeleteButton> }
            </Datagrid>
            ) : (
                <MobileUsuarioList checkPermiso = { checkPermiso } />
            )
            }
        </List>
        <Route
            path="/usuarios/create"
            render={() => (
                <Drawer anchor="right" open onClose={handleClose}>
                    <UsuarioCreate {...props} onCancel={handleClose} onSuccessOk= { onSuccess } />
                </Drawer>
            )}
         />
         <Route path="/usuarios/:id">
            {({ match }) => {
                const isMatch = match && match.params && match.params.id !== "create";
                return (
                <Drawer open={isMatch} anchor="right" onClose={handleClose}>
                    {isMatch ? (
                    <UsuarioEdit
                        id={match.params.id}
                        record={match.params}
                        onCancel={handleClose}
                        onSuccessOk= { onSuccessEdit }
                        {...props}
                    />
                    ) : null}
                </Drawer>
                );
            }}
        </Route>
    </div> 
)};

export default withWidth()(UsuariosList);
