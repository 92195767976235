import frenchMessages from 'ra-language-french';

const customFrenchhMessages = {
    ...frenchMessages,
    traducciones: {
        menu: {
            administracion : 'Administration',
            usuarios: 'Utilisateurs',
            grupos: 'Groupes',
            estructura: 'Structure',
            nodos : 'Nœuds',
            indices : 'Indices'
        },
        titulos:{
            nuevoUsuario : 'Nouvel utilisateur',
            nuevoGrupo : 'Nouveau groupe',            
            editarUsuario : 'Modifier l\'utilisateur',
            editarGrupo : 'Modifier le groupe',
            gestionDeGrupos : 'Gestion de groupe',
            gestionDeUsuarios : 'Gestion des utilisateurs',
            addNodosHijos : 'Ajouter des enfants à ce nœud',
            addNodosPrincipal : 'Ajouter un nœud principal',
            deleteNodo : 'Supprimer ce nœud',
            nuevoNodo : 'Nouveau nœud',
            nodoPadre : 'Nœud parent',
            gestionDeNodos : 'Gestion des nœuds',
            gestionDeIndices : 'Gestion des index',
        },
        permisos: {
            anotaciones : 'Annotations',
            exportar : 'Exporter',
            importar : 'importer',
            digitalizar : 'Numériser',
            imprimir : 'Imprimer',
            enviar : 'Envoyer',
        },
        campos: {
            nombre : 'Nom',
            nombreDeGrupo : 'Nom de groupe',
            email : 'Courrier électronique',
            rol : 'Rôle d\'utilisateur',
            estadoPassword: 'Statut du mot de passe',
            estadoUsuario: 'Statut de l\'utilisateur',
            vigenciaPassword: 'Validité du mot de passe (jours)',
            nodoNombre : 'Nom du nœud',
            indice : 'Indice',
            tipo : 'Type d\'index',
            mascara : 'Masque',
            minimo : 'Minimum',
            maximo : 'Maximum',
            longitud : 'Longueur',
            requerido : 'Obligatoire',
            multiple : 'Multiple',
            unico : 'Unique',
            verificar : 'vérifier',
            repetido : 'Répété',
            diccionario : 'dictionnaire',
            biometrico : 'Biométrique',

        },
        notificaciones: {
            addUsuarioOk : 'Utilisateur créé avec succès',
            editUsuarioOk : 'Utilisateur modifié avec succès',
            addGrupoOk : 'Groupe créé avec succès',
            editGrupoOk : 'Groupe modifié avec succès',
        },
        modal : {
            eliminarGrupo : 'Supprimer le groupe',
            eliminarGrupoConfirm : 'Voulez-vous vraiment supprimer ce groupe?',
            eliminarUsuario : 'Eliminar Usuario',
            eliminarUsuarioConfirm : 'Voulez-vous vraiment supprimer cet utilisateur?',
            eliminarNodo : 'Supprimer le nœud',
            eliminarNodoConfirm : 'Voulez-vous vraiment supprimer ce nœud?'
        },
        roles : {
            administrador : 'Administrateur',
            seguridad : 'Sécurité',
            supervisor : 'Superviseur',
            operador : 'Opérateur',
            usuario : 'Utilisateur',
        },
        estadoPassword : {
            caducado : 'Fin du temps',
            vigente : 'Valide' ,
            noCaduca : 'N\'expire pas' ,
            temporalCaduca : 'Temporaire qui expire' ,
            temporalNoCaduca : 'Temporaire qui n\'expire pas',
        }
    }
}

export default customFrenchhMessages;