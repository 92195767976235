import React from 'react';
import { useState } from 'react';
import {
  Card, CardContent,
} from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentPreview from '../../Components/DocumentPreview';
import {
    useTranslate,
    useDataProvider
  } from 'react-admin';

const useStyles = makeStyles({
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: '0.5rem 0',
      borderRadius : '16px',
      marginBottom: '15px',
      boxShadow: '1px 3px 7px #3b824b',
      paddingBottom : "10px"
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContentRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
    cardHeader : {
      backgroundColor: '#525b59',
      paddingBottom: '10px',
      paddingTop: '10px',
      color: 'white'
    }
  });

const CardResultado = (props) => {

    const classes = useStyles();
    const translate = useTranslate();
    const dataSearch = props.dataSearch;
    const [visor, setVisor] = useState(false); 
    const [document, setDocument] = useState(null); 

    const dataProvider = useDataProvider();

    const consultaDocumento = (elem) => {
        dataProvider.getOne('documento', { IdDocument: elem.IdDocument})
            .then(({ data }) => {
                data.data.IdDocument = elem.IdDocument;
                setDocument(data.data);
                setVisor(true);
            })
            .catch(error => {
        });
    } 

    return  (
    <Card className={classes.card}>    
    <CardHeader
                className={classes.cardHeader }
                title={
                <div className={classes.cardTitleContent} alignItems="center">
                        <span style={{ fontSize: 'large' }}>
                        { translate("traducciones.titulos.resultado")} { props.i} / {dataSearch.total}
                        </span>
                    </div>
                }
            />     
    <CardContent>                                               
    {
        dataSearch && dataSearch.data && dataSearch.data.length > 0 && dataSearch.data[0].DatosDocumento && dataSearch.data[0].DatosDocumento.map(({ NombreIndice },inden) => {
            return  (
                <div>
                    <div style={{ padding: '5px' }}>
                        <a onClick={() => props.cambiaOrden(NombreIndice) } >
                        <strong style={{color: '#319b42'}}>{ NombreIndice }: </strong>
                        { NombreIndice == props.order ? (
                        <span>
                        { props.sort ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}    
                        </span>                     
                        ) : null
                        }     
                        { props.elem.DatosDocumento[inden].ValorLlave }                                  
                        </a>
                    </div>
                </div>
                )
            }
        )            
        }
    
     </CardContent>    

        <div align="center" style={{display:'flex', padding: '5px', marginTop: '5px'}}>
            <div style={{width: '50%'}}>
                <a onClick={() => consultaDocumento(props.elem) } >
                    <VisibilityIcon /> 
                </a>
            </div>
            <div style={{width: '50%'}}>
                <DeleteIcon/>
            </div>
        </div>

        {
        visor ? (
            <DocumentPreview document = { document } clicked= { true } callback = { () => setVisor(false) } />
        ): null
        }
    </Card>
        )
}

export default CardResultado;

