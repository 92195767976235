import { AUTH_LOGIN, AUTH_CHECK,AUTH_LOGOUT,AUTH_ERROR, DeleteWithUndoButton } from 'react-admin';
import jwt_decode from "jwt-decode";

export const AuthProvider = async(type, params) => {

    //Funcion para realizar el inicio de session
    if (type === AUTH_LOGIN) {

        const { user, password } = params;

        //Obtenemos la Ip Publica
        const publicIp = require("react-public-ip");
        const ipv4 = await publicIp.v4() || "";

        //Obtenemos la url base de todas las apis que esta guardada en una variable global de JS en el index.
        const apiUrl = window.$parametros_generales.REACT_APP_BASE_URL;
        
        
        //Realizamos el Loguin
        const request = new Request(apiUrl + 'Login', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json'}),
            body : JSON.stringify({
                "UserName": user,
                "Password": password,
                "MacAddress": "AC-D5-64-23-13-9D", //Estos datos estan hardcodeados por que que no se pueden obtener desde el cliente JS
                "StationName": "Dust-Elias", //Estos datos estan hardcodeados por que que no se pueden obtener desde el cliente JS
                "Ip": ipv4,
                "Client": "DEVELOPMENT" //Se debe cambiar este perfil.
            })
        })
        
        return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .catch((error) => {
            throw new Error(error)
        })
        .then(({ Token, Message }) => {
            if(Token){
                //Si el login es correcto guardamos el token y todas las variables de session importantes para la plataforma.
                localStorage.setItem('token', Token);
                var decoded = jwt_decode(Token); 
                localStorage.setItem("userName", decoded.unique_name);
                localStorage.setItem("userRole", decoded.role); 
                localStorage.setItem("userNombre",decoded.unique_name);    
                localStorage.setItem("IdUsuario",decoded.IdUsuario);                                    
                return Promise.resolve(true);                 
            }
            else{
                throw new Error(Message);        
            }
        });

            
        
    }
    //Funcion para realizar el cierre de session
    if (type === AUTH_LOGOUT) {

        const apiUrl = window.$parametros_generales.REACT_APP_BASE_URL;    

        if(localStorage.getItem('token')  != null && localStorage.getItem('token')  != undefined){
            //Llamamos a la api para eliminar o cerrar la session.        
            const request = new Request(apiUrl + 'Sessions/logOut', {
                method: 'DELETE',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })
            });      

            //Removemos las variables de session.
            localStorage.removeItem('token');
            localStorage.removeItem('userName');
            localStorage.removeItem('userRole');
            localStorage.removeItem('permisos');

            try{
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .catch(error => {    
                    window.location.href = "#/Login";
                })
                .then(({ Success, Message }) => {
                    if(Success){
                        return Promise.resolve();              
                    }
                    else{
                        return Promise.resolve();        
                    }
                });
            }catch(e){
                return Promise.resolve(); 
            }
        }else{
            return Promise.resolve();
        }
        
    }

    //En caso de desconectarse o no tener token valido cerramos la session local y redirijimos al login
    if (type === AUTH_ERROR) {
        if (params.message.toLowerCase().includes("ERR0R09222022")) {
            localStorage.removeItem('token');
            localStorage.removeItem('userName');
            localStorage.removeItem('userRole');
            localStorage.removeItem('permisos');
            window.location.href = "#/Login";
        }
        return Promise.resolve()
    }

    //Verificacion permanente de se el token es valido.
    if (type === AUTH_CHECK) {
        if(!localStorage.getItem('token')){
            localStorage.removeItem('token');
            localStorage.removeItem('userName');
            localStorage.removeItem('userRole');
            localStorage.removeItem('permisos');
            window.location.href = "#/Login";
        }
        return Promise.resolve()
    }

    //Verificacion comun en cada una de las ventanas para checkear los permisos y poder realizar actividades de seguridad.
    if(type === "AUTH_GET_PERMISSIONS"){
        const token = localStorage.getItem('token');
        var decoded = jwt_decode(token);        
        const permisos = JSON.parse(decoded.Permisos).RolePermissions;
        var permissions = {};
        permisos.forEach(function (record) { 
                var permisoLsit = {};
                record.Permissions.forEach(function (perm) {
                    permisoLsit[perm.PermissionName.replaceAll(" ","")] = perm.Value; 
                });
                permissions[record.Module.replaceAll(" ","")] = permisoLsit;
        });
        
        return Promise.resolve(permissions);

    }

    return Promise.resolve();
}

/*
La documentacion de como crear un Auth provider personalizado de react admin es la siguiente:
https://marmelab.com/react-admin/doc/3.18/Authentication.html
*/