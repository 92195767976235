import * as React from 'react';
import { useState } from 'react';
import { 
    useLogin, 
    useNotify, 
    Notification, 
    defaultTheme, 
    useTranslate,
    SimpleForm,
    SaveButton,
    Button,
    TextInput,
    required,
    useDataProvider,
    Toolbar,
    Loading,
    PasswordInput 
} from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import EmailIcon from '@material-ui/icons/Email';
import "../login.css"

export const LoginPage = ({ theme }) => {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [openRecupera, setOpenRecupera] = useState(false);
    const [openCambia, setOpenCambia] = useState(false);
    const login = useLogin();
    const notify = useNotify();
    const submit = e => {
        e.preventDefault();
        setLoading(true);
        login({ user, password }).then((resp) => {
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            notify(error.message,'error',null,false,50000);
        }
        );
    };

    const dataProvider = useDataProvider();

    const handleRecupera = async values => {
        dataProvider.password('restore', { Email: values.emailRecupera})
        .then((json) => {
            notify(json.data.Message);
            setOpenRecupera(false);
            return;
        })
        .catch(error => {
        }); 
    }

    const handleCambiaPass = async values => {
        if(values.NewPassword != values.pass2){
            notify("Las contraseñas no coinciden", "error");
            return;
        }
        dataProvider.password('change', { values })
        .then((json) => {
            if(json.data.Success){
                notify(json.data.Message);
                setOpenCambia(false);
            }else{
                notify(json.data.Message,'error',null,true,5000);
                return;
            }
        })
        .catch(error => {
        }); 
    };

    const translate = useTranslate();

    if(loading){
        return <div style={{ marginTop: '10%'}} ><Loading loadingPrimary={translate('traducciones.titulos.ingresando')} /></div>
    }

    return (
        <ThemeProvider theme={createTheme(defaultTheme)}>
            <div className="login_div" style={{  background: "url(background.png)", backgroundSize: '100% 100%'}}>
                <div class="row" style={{ background: "#ececee", height: '15vh'}}>
                    <div class="col-md-2">
                        <img style={{ height: "15vh", padding: "20px" }} src={"logowide.png"}  />
                    </div>
                    <div class="col-md-8" style={{ paddingTop : '6vh', paddingRight: '30px', textAlign: 'right', fontSize: '0.9em !important;' }}>
                        <span style={{ marginRight: '15px', fontWeight: 'bold', color: 'black' }}>{translate('traducciones.login.primera')}</span>
                    </div>
                    <div class="col-md-2" style={{ textAlign: 'center', display: 'flex', alignItems: 'center'}}>
                        <input type='button' className='btn_pass' onClick={e => setOpenCambia(true)} value="Cambiar Contraseña" />
                    </div>
                </div>
                <div style={{ height: '70vh' }} >
                    <SimpleForm onSubmit={submit} className="formInicio" toolbar={null}>
                        <div className="row" fullWidth>
                                <div class="col-md-7">
                                    <div class="enunciado">
                                        <div class="enunciado_texto">
                                        "{translate('traducciones.login.mensaje')}"
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5" style={{position: 'relative'}}>
                                    <div class="content_login">
                                        <TextInput
                                            label="Usuario"
                                            name="text"
                                            value={user}
                                            style={{width: '100%'}}
                                            onChange={e => setUser(e.target.value)}
                                        />
                                        <PasswordInput
                                            name="password"
                                            label="Contraseña"
                                            value={password}
                                            style={{width: '100%'}}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                        <div style={{textAlign: 'center'}}>
                                            <div class="password_recovery" style={{cursor: "pointer"}}>
                                                <a  onClick={e => setOpenRecupera(true)}>{translate('traducciones.login.olvidepass')}</a>
                                            </div>
                                            <input type="submit" value="Iniciar Sesión" />
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </SimpleForm>
                </div>
                <div style={{ height: '15vh', backgroundColor: "white"}}>
                    <div class="row">
                        <div class="col-md-5" style={{ position: 'relative', height: '15vh'}}>
                            <div className="copyright">
                                © {(new Date().getFullYear())} Maximage Data Systems
                            </div>
                        </div>
                        <div class="col-md-2" style={{ position: 'relative', height: '15vh', textAlign : 'center'}}>
                            <img src='ISO27001.png' style={{ height: "70%", marginTop: "5%"}} />
                        </div>
                        <div class="col-md-5" style={{ position: 'relative', height: '15vh'}}>
                            <div className="copyright" >
                                <a href="#">{translate('traducciones.login.ayuda')}</a>
                                <a href="https://www.maximage-ds.com/contacto" target="_blank" style={{ marginLeft: '30px'}}>{translate('traducciones.login.contactenos')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                 
                <Notification />


            </div>
            <div>
                <Dialog open={openRecupera}>
                    <DialogTitle>{ translate('traducciones.titulos.restablecerContraseña')}</DialogTitle>
                    <DialogContent>
                        <SimpleForm save={handleRecupera} noValidate toolbar = { 
                            <Toolbar style={{ justifyContent: "space-between" }}>
                                <SaveButton  label ="traducciones.login.restablecer" icon={<EmailIcon />} /> 
                                <Button label ="ra.action.cancel"  onClick={e => setOpenRecupera(false)} />
                            </Toolbar> } >
                            <TextInput style={{ minWidth: "400px"}} source="emailRecupera" label="traducciones.campos.email" validate={required()} />
                        </SimpleForm>
                    </DialogContent>
                </Dialog> 
                <Dialog open={openCambia}>
                    <DialogTitle>{ translate('traducciones.titulos.cambioContraseña')}</DialogTitle>
                    <DialogContent>
                        <SimpleForm save={handleCambiaPass} noValidate toolbar = { 
                            <Toolbar style={{ justifyContent: "space-between" }}>
                                <SaveButton  label ="traducciones.login.restablecer" icon={<EmailIcon />} /> 
                                <Button label ="ra.action.cancel"  onClick={e => setOpenCambia(false)} />
                            </Toolbar> } >
                            <TextInput style={{ minWidth: "400px"}} source="UserName" label="traducciones.campos.usuario" validate={required()} />
                            <PasswordInput  style={{ minWidth: "400px"}} source="CurrentPassword" label="traducciones.campos.passwordOld" validate={required()} />
                            <PasswordInput  style={{ minWidth: "400px"}} source="NewPassword" label="traducciones.campos.passwordNew" validate={required()} />
                            <PasswordInput  style={{ minWidth: "400px"}} source="pass2" label="traducciones.campos.passwordNewRepeat" validate={required()} />
                        </SimpleForm>
                    </DialogContent>
                </Dialog> 
            </div>
        </ThemeProvider>
    );
};